import { combineReducers } from 'redux';

import loaderReducer from './common/loaderReducer'
import userReducer from './user/userReducer'
import countryStateCityReducer from './common/countryStateCityReducer'
import serviceReducer from './services/serviceReducer'
import classesReducer from './classes/classesReducer'
import companyReducer from './company/companyReducer'
import packageReducer from './packages/packagesReducer'
import scheduleReducer from './schedules/scheduleReducer'
import registrationReducer from './registration/registrationReducer'
import globalsReducer from './common/globalsReducer'
import instructorReducer from './instructor/instructorReducer'
import signatureReducer from './common/signatureReducer'

const rootReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  countryStateCity: countryStateCityReducer,
  service: serviceReducer,
  classes: classesReducer,
  company: companyReducer,
  package: packageReducer,
  schedule: scheduleReducer,
  registration: registrationReducer,
  globals: globalsReducer,
  instructor: instructorReducer,
  signature: signatureReducer,
})

export default rootReducer