import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { Service } from '../../../_common/interfaces/models/service';
import { ACTIONS } from '../../../_config'

export interface ServiceReducer {
  serviceList: Service[];
  details: Service | null;
}

const initialState: ServiceReducer = {
  serviceList: [],
  details: null,
};

const serviceReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.SERVICE.LIST:
      return {
        ...state,
        serviceList: action.payload,
      };
    case ACTIONS.SERVICE.DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case ACTIONS.SERVICE.RESET_LIST:
      return {
        ...state,
        serviceList: [],
      };
    case ACTIONS.SERVICE.RESET_DETAILS:
      return {
        ...state,
        details: null,
      };
    default:
      return state;
  }
};

export default serviceReducer;
