import React, { useLayoutEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { useAppSignatureAction } from '../../_common/hooks/actions/appSignatureActions'
import { useSignatureApi } from '../../_common/hooks/actions/appSignatureApiHook'
import { useToaster } from '../../_common/hooks/actions/appToasterHook'
import { useAppSelectedSignatureSelector, useAppSignaturePendingListSelector } from '../../_common/hooks/selectors/signatureSelector'
import { getFileFromDataUrl } from '../../_config'

import './signatureModal.css'

import {
  FiCheckCircle,
} from 'react-icons/fi'

function SignatureInputView() {
  /**
   * const
   */
  const [canvasProperties, setCanvasProperties] = useState({
    width: 500,
    height: 250,
    className: 'sigCanvas',
  }) 
  const signatureAction = useAppSignatureAction()
  const [ validationError, setValidationError ] = useState('')
  const [ isSuccess, setIsSuccess ] = useState(false)
  const signatureApi = useSignatureApi()
  const toast = useToaster();
  const selected = useAppSelectedSignatureSelector()
  let sigCanvas = useRef<any>()

  const [ email, setEmail ] = useState('')
  const [ name, setName ] = useState('')

  /**
   * effects
   */
  useLayoutEffect(() => {
    let doc = document.getElementsByClassName('list-sign-canvas-col')[0]

    if (doc) {
      let width = doc.getBoundingClientRect().width
      setCanvasProperties({
        ...canvasProperties,
        width: width - 30,
      })
    }
  }, [])
  /**
   * functions
   */
  const goBackToList = () => {
    signatureAction.resetSelected()
    signatureAction.setListViewMode()
  }
  const onWriteEnd = () => {
  }
  const onWriteBegin = () => {
    setValidationError('')
  }
  const submitSign = () => {
    let isEmpty = sigCanvas.current.isEmpty()
    if (isEmpty) {
      setValidationError('Please sign first');
      return
    }

    if (selected && selected.class_type_id == 2) {
      if (!email) {
        setValidationError('Email address is required');
        return
      }
      if (!name) {
        setValidationError('Name is required');
        return
      }
    }
    setValidationError('');
    let imgDataUrl = sigCanvas.current.toDataURL('image/png', 1);
    let file = getFileFromDataUrl(imgDataUrl)

    if (!file) {
      toast.error('Unable to save signature, please try again')
      return
    }
    let fd = new FormData()
    fd.append('signature', file, file.name)
    fd.append('id', selected ? String(selected.id) : '')
    fd.append('class_type_id', selected ? String(selected.class_type_id) : '')

    if (selected && selected.class_type_id == 2) {
      fd.append('parent_email', email)
      fd.append('parent_name', name)
    }

    signatureApi.callUploadSignature(fd, (message: string) => {
      toast.success(message)
      setIsSuccess(true);
      setTimeout(() => {
        signatureAction.resetSelected();
        signatureAction.setListViewMode();
      }, 2000);
    }, (message: string) => {
      toast.error(message)
    })
  }
  /**
   * render functions
   */
  return (
    <React.Fragment>
      <div className="list-sign-container">
        <div className="list-sign-canvas-info-col">
          <span className="list-sign-canvas-info-session">{selected ? selected.title : 'N/A'}</span>
          <span className="list-sign-canvas-info-time">{selected ? selected.formattedDateString : 'N/A'}</span>
        </div>
        <div className="row list-sign-canvas-row">
          <div className="col-12 list-sign-canvas-col">
            {
              isSuccess ? <div className="sig-saved-container">
                <span className="sig-saved-icon">
                  <FiCheckCircle/>
                </span>
                <p className="sig-saved-text">
                  Signature saved successfully!
                </p>
              </div> : <>
                <p className="list-sign-canvas-col-header">Sign here</p>
                <SignatureCanvas
                  penColor='black'
                  canvasProps={canvasProperties}
                  onEnd={() => onWriteEnd()}
                  onBegin={() => onWriteBegin()}
                  ref={sigCanvas}
                  backgroundColor="rgb(255,255,255,1)"
                />
                {
                  selected && selected.class_type_id == 2 ? <div className="row">
                    <div className="col-6">
                      <input onChange={(evt) => {
                        setEmail(evt.target.value)
                      }} type="email" name="email" placeholder="Email Address" className="form-control" />
                    </div>
                    <div className="col-6">
                      <input onChange={(evt) => {
                        setName(evt.target.value)
                      }} type="text" name="name" placeholder="Name" className="form-control" />
                    </div>
                  </div> : null
                }
              </>
            }
          </div>
        </div>
        {
          validationError ? <div className="row">
            <div className="col-12">
              <span className="text-danger font-error">{validationError}</span>
            </div>
          </div> : null
        }
        {
          isSuccess ? null : <div className="row list-sign-action-row">
            <div className="col-12 list-sign-action-col">
              <button onClick={goBackToList} className="btn btn-danger list-sign-btn">Cancel</button>
              <button onClick={submitSign} className="btn btn-success list-sign-btn">Save</button>
            </div>
          </div>
        }
      </div>
      <div className="sign-label-container">
        <span className="sign-label-sub-title">Sign with your finger on a touchscreen device or mouse/track-pad on a PC or Mac. </span>
      </div>
    </React.Fragment>
  )
}

export default SignatureInputView
