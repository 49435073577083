import React from 'react';

const LazyRequestDriving = React.lazy(() =>
  import(/* webpackChunkName: "RequestDrivingStudent" */ './requestDriving')
);

const RequestDriving = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyRequestDriving {...props} />
  </React.Suspense>
);

export default RequestDriving