import { ACTIONS, SAGA_ACTIONS } from '../../../_config'
import { ApplyRemoveBTWReq, BookMakeupClassReq, ClassListReq, InstructorAvailabilityListForStudentReq, InstructorAvailabilityListReq, MakeupClassListReq, MyClassListReq, ServiceListReq, StudentClassConditionReq, UpdateParentClassReq } from '../../interfaces/ApiReqRes'
import { useApiCall } from './appApiCallHook'

export function useClassesApi() {

  const callApi = useApiCall()

  const getClassesList = (data: ClassListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.LIST, data, onSuccess, onError);
  }
  const getMyClassesList = (data: MyClassListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.MY_CLASS_LIST, data, onSuccess, onError);
  }
  const getClassesDetails = (data: MyClassListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.DETAILS, data, onSuccess, onError);
  }
  const getClassTypeList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.CLASS_TYPE_LIST, null, onSuccess, onError);
  }
  const getClassInstructorAvailabilityList = (data: InstructorAvailabilityListForStudentReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.INSTRUCTOR_AVAILABILITY, data, onSuccess, onError);
  }
  const getBTWClassList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.BTW_LIST, null, onSuccess, onError);
  }
  const applyBTW = (data: ApplyRemoveBTWReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.APPLY_BTW, data, onSuccess, onError);
  }
  const removeBTW = (data: ApplyRemoveBTWReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.REMOVE_BTW_REQUEST, data, onSuccess, onError);
  }
  const getClassConditions = (data: StudentClassConditionReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.STUDENT_CLASS_CONDITION, data, onSuccess, onError);
  }
  const getMakeupClassList = (data: MakeupClassListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.MAKEUP_CLASS_LIST, data, onSuccess, onError);
  }
  const bookMakeupClass = (data: BookMakeupClassReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.BOOK_MAKEUP_CLASS, data, onSuccess, onError);
  }
  const getParentClassList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.PARENT_CLASS_LIST, null, onSuccess, onError);
  }
  const updateParentClass = (data: UpdateParentClassReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CLASSES.PARENT_CLASS_UPDATE, data, onSuccess, onError);
  }

  return {
    callClassesList: getClassesList,
    callMyClassesList: getMyClassesList,
    callClassesDetails: getClassesDetails,
    callClassTypeList: getClassTypeList,
    callGetClassInstructorAvailabilityList: getClassInstructorAvailabilityList,
    callGetBTWClassList: getBTWClassList,
    callApplyBTW: applyBTW,
    callRemoveBTW: removeBTW,
    callGetClassConditions: getClassConditions,
    callGetMakeupClassList: getMakeupClassList,
    callBookMakeupClass: bookMakeupClass,
    callGetParentClassList: getParentClassList,
    callUpdateParentClass: updateParentClass,
  }
}