import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../_config'
import { PendingSignature } from '../../interfaces/models/pendingSignature'

export function useAppSignatureAction() {

  const dispatch = useDispatch()

  const setShowModal = () => {
    console.log('showing modal')
    dispatch({
      type: ACTIONS.SIGNATURE.SET_MODAL_STATUS,
      payload: true,
    })
  }
  const setHideModal = () => {
    console.log('hiding modal')
    dispatch({
      type: ACTIONS.SIGNATURE.SET_MODAL_STATUS,
      payload: false,
    })
  }
  const setListViewMode = () => {
    dispatch({
      type: ACTIONS.SIGNATURE.SET_MODAL_LIST_MODE,
      payload: null,
    })
  }
  const setListSignMode = () => {
    dispatch({
      type: ACTIONS.SIGNATURE.SET_MODAL_SIGN_MODE,
      payload: null,
    })
  }
  const setSelected = (sig: PendingSignature) => {
    dispatch({
      type: ACTIONS.SIGNATURE.SET_SELECTED_SESSION,
      payload: sig,
    })
  }
  const resetSelected = () => {
    dispatch({
      type: ACTIONS.SIGNATURE.SET_SELECTED_SESSION,
      payload: null,
    })
  }
  const resetAll = () => {
    dispatch({
      type: ACTIONS.SIGNATURE.RESET,
      payload: true,
    })
  }

  return {
    setShowModal,
    setHideModal,
    setListViewMode,
    setListSignMode,
    resetAll,
    setSelected,
    resetSelected,
  }
}