import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MyPackage } from '../../interfaces/models/myPackage'
import { Package } from '../../interfaces/models/package'
import { PackageTransaction } from '../../interfaces/models/packageTransaction'
import { StudentDueAmount } from '../../interfaces/models/studentDueAmount'
import { StateExtended } from '../../interfaces/StateExtended'


export function useAppPackageListSelector() {
  const list: Package[] = useSelector((state: StateExtended) => state.package.packageList)

  return list
}

export function useAppMyPackageListSelector() {
  const list: MyPackage[] = useSelector((state: StateExtended) => state.package.myPackageList)

  return list
}

export function useAppPackageDetailsSelector() {
  const details: Package | null = useSelector((state: StateExtended) => state.package.packageDetails)

  return details
}

export function useAppPackageTransactionHistorySelector() {
  const list: PackageTransaction[] = useSelector((state: StateExtended) => state.package.transactionHistory)

  return list
}

export function useAppPackageStudentDueListSelector() {
  const list: StudentDueAmount[] = useSelector((state: StateExtended) => state.package.studentDueList)

  return list
}

export function useAppPaymentReminderModalShownSelector() {
  const shown = useSelector((state: StateExtended) => state.package.paymentReminderModalShown)

  return shown
}