export const TWITTER_LINK = 'https://twitter.com/home?lang=en'
export const VISA_LINK = 'https://www.visa.co.in/'
export const MASTER_CARD_LINK = 'https://www.mastercard.co.in/en-in.html'
export const GODADDY_LINK = 'https://in.godaddy.com/'

export const USER_DEFAULT_IMAGE = '/images/default_user.png'

export const DOB_MONTHS = [
  {value: 1, label: 'January'},
  {value: 2, label: 'February'},
  {value: 3, label: 'March'},
  {value: 4, label: 'April'},
  {value: 5, label: 'May'},
  {value: 6, label: 'June'},
  {value: 7, label: 'July'},
  {value: 8, label: 'August'},
  {value: 9, label: 'September'},
  {value: 10, label: 'October'},
  {value: 11, label: 'November'},
  {value: 12, label: 'December'},
]

export const HEAR_FROM_OPTIONS = [{
  label: 'A Friend',
  value: 'A Friend'
}, {
  label: 'A Sibling',
  value: 'A Sibling'
}, {
  label: 'Flyer',
  value: 'Flyer'
}, {
  label: 'Google Search',
  value: 'Google Search'
}, {
  label: 'Saw car in the street',
  value: 'Saw car in the street'
}, {
  label: 'Walk in',
  value: 'Walk in'
}]

export const CONTACT_US_SUBJECTS = [{
  label: 'Question',
  value: 'Question'
}, {
  label: 'Please Contact Me',
  value: 'Please Contact Me'
}, {
  label: 'Suggestion',
  value: 'Suggestion'
}, {
  label: 'Other',
  value: 'Other'
}]

export const YES_NO_RADIO = [{
  label: 'No',
  value: 'no'
}, {
  label: 'Yes',
  value: 'yes'
}]

export const GENDER_RADIO = [{
  label: 'Male',
  value: 'male'
}, {
  label: 'Female',
  value: 'female'
}, {
  label: 'Other',
  value: 'other'
}]

export const CLASS_APPOINTMENT_STATUS_OPTIONS = [{
  label: 'Present',
  value: '1'
}, {
  label: 'Absent',
  value: '0'
}]

export const PROFILE_IMAGE_ASPECT_RATIO = 1