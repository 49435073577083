import React, { useEffect, useState } from 'react'

import {
  Nav,
  Navbar,
  NavDropdown,
  Container,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { URLS } from '../../../_config'

import './topMenu.css'
import { useAppGlobalAction } from '../../hooks/actions/appGlobalActionHook'
import { useAppUserAuthSelector, useAppUserDetailsSelector } from '../../hooks/selectors/userSelector'
import { UserType } from '../../interfaces/models/_common'
import { useUserApi } from '../../hooks/actions/appUserApiHook'
import { useToaster } from '../../hooks/actions/appToasterHook'
import { useAppCompanyDetailsSelector } from '../../hooks/selectors/companySelector'
import { useAppServiceListSelector } from '../../hooks/selectors/serviceSelector'
import { useServiceApi } from '../../hooks/actions/appServiceApiHook'
import { useStaticApi } from '../../hooks/actions/appStaticApiHook' 
import AppPaymentReminderRoot from '../../../components/paymentReminder/paymentReminderRoot'
import { useAppMenuListSelector } from '../../hooks/selectors/staticSelector'

function TopMenu() {

  /**
   * const
   */
  const company = useAppCompanyDetailsSelector()
  const services = useAppServiceListSelector();
  const serviceApi = useServiceApi()
  const history = useHistory()
  const staticApi = useStaticApi()
  const menuList = useAppMenuListSelector();
  const [activeRoute, setActiveRoute] = useState(URLS.HOME)
  const globalActions = useAppGlobalAction()
  const isAuth = useAppUserAuthSelector()
  const user = useAppUserDetailsSelector()
  const userApi = useUserApi()
  const toast = useToaster()
  /**
   * effects
   */
  useEffect(() => {
    if (company) {
      staticApi.callGetMenuList({
        company_id: company.id,
      }, () => {}, (message: string) => {
        toast.error(message)
      })
    }
  }, [company])
  useEffect(() => {
    if (history.location.pathname.startsWith(URLS.SERVICES_ONLY)) {
      setActiveRoute(URLS.SERVICES_ONLY)
    } else {
      setActiveRoute(history.location.pathname);
    }
  }, [history.location])

  useEffect(() => {
    if (!services && company) {
      serviceApi.callServiceList({
        company_id: company.id,
      }, () => {}, (message: string) => {
        toast.error(message)
      })
    }
  }, [company])

  /**
   * functions
   */

  /**
   * Open login modal
   * @param evt 
   */
  
  const openLoginModal = (evt: React.MouseEvent) => {
    evt.preventDefault()
    globalActions.showLoginModal();
  }

  const logout = (evt: React.MouseEvent) => {
    evt.preventDefault()
    userApi.callLogout(() => {}, (message: string) => {
      toast.error(message)
    })
  }
  // console.log(menuList);
  // if (!menuList || !menuList.length) {
  //   return null
  // }
  console.log(services, user?.show_instructor_calendar_buttons);
  return (
    <React.Fragment>
      <Navbar collapseOnSelect={true} bg="dark custom-menu" expand="md">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <li className={activeRoute == URLS.HOME ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to={URLS.HOME}>Home</Link>
              </li>
              <NavDropdown title="Services" className={activeRoute == URLS.SERVICES_ONLY ? 'active' : ''} id="basic-nav-dropdown">
                {
                  services?.map((service, index) => {
                    return <Link key={index} className="dropdown-item" to={`${URLS.SERVICES_ONLY}/${service.slug}`}>{service.title}</Link>
                  })
                }
              </NavDropdown>
              <NavDropdown title="Links" className={activeRoute == URLS.SERVICES_ONLY ? 'active' : ''} id="basic-nav-dropdown">
                <Link className="dropdown-item" to={URLS.FAQ}>Faq</Link>
                <Link className="dropdown-item" to={URLS.TWITTER_HELP}>Twitter help</Link>
              </NavDropdown>
              {/* <li className={activeRoute == URLS.ABOUT_US ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to={URLS.ABOUT_US}>About</Link>
              </li>
              
              <li className={activeRoute == URLS.LOCATIONS ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to={URLS.LOCATIONS}>Locations</Link>
              </li>
              
              <li className={activeRoute == URLS.CONTACT_US ? 'nav-item active' : 'nav-item'}>
                <Link className="nav-link" to={URLS.CONTACT_US}>Contact</Link>
              </li> */}
              {
                menuList?.map((menu, index) => {
                  if(menu.status == 0 || menu.type == 2){
                    return null;
                  }else{
                    return <li className={activeRoute == URLS.CONTACT_US ? 'nav-item active' : 'nav-item'}><Link className="nav-link" to={`${menu.link}`}>{menu.name}</Link></li>
                  }
                })
              }
              {
                isAuth && user ? <>
                  <NavDropdown title="My Account" className={activeRoute == URLS.ACCOUNT_SECTION ? 'active' : ''} id="basic-nav-dropdown">
                    {
                      user.user_type === UserType.student ? <>
                        <Link className="dropdown-item" to={URLS.STUDENT.MY_CLASSES}>My Classes</Link>
                        <Link className="dropdown-item" to={URLS.STUDENT.MY_PACKAGES}>My Packages</Link>
                        <Link className="dropdown-item" to={URLS.STUDENT.MY_PROFILE}>My Profile</Link>
                      </> : user.user_type == UserType.instructor ? <>
                        <Link className="dropdown-item" to={URLS.INSTRUCTOR.MY_APPOINTMENTS}>My Appointments</Link>
                        {(user?.show_instructor_calendar_buttons == 1) ?
                        <Link className="dropdown-item" to={URLS.INSTRUCTOR.MY_CALENDAR}>My Calendar</Link> : ''}
                        <Link className="dropdown-item" to={URLS.INSTRUCTOR.MY_PROFILE}>My Profile</Link>
                      </> : null
                    }
                  </NavDropdown>
                </> : null
              }
            </Nav>
            {
              isAuth && user ? <div className="student-login">
                <Link to={user.user_type == UserType.student ? URLS.STUDENT.MY_PROFILE : URLS.INSTRUCTOR.MY_PROFILE}><img src="/images/user-icon.png" alt="" /> Hello, <strong>{user.first_name}</strong> </Link>
                <a href="#" onClick={logout}><span><img src="/images/logout-icon.png" alt="" /></span></a>
              </div> : <div className="student-login">
                  <a href="#" onClick={openLoginModal}><img src="/images/login-icon.png" alt="" /> Login</a>
                </div>
            }

          </Navbar.Collapse>
        </Container>
        <AppPaymentReminderRoot/>
      </Navbar>
    </React.Fragment>
  )
}

export default TopMenu
