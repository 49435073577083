import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { ClassSchedule } from '../../../_common/interfaces/models/classSchedule';
import { Service } from '../../../_common/interfaces/models/service';
import { ACTIONS } from '../../../_config'

export interface ScheduleReducer {
  scheduleList: ClassSchedule[];
}

const initialState: ScheduleReducer = {
  scheduleList: [],
};

const scheduleReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.SCHEDULES.LIST:
      return {
        ...state,
        scheduleList: action.payload,
      };
    case ACTIONS.SCHEDULES.RESET_LIST:
      return {
        ...state,
        scheduleList: [],
      };
    default:
      return state;
  }
};

export default scheduleReducer;
