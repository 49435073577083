import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../_config';
import { CallApi } from './api/callApi';

export function* getScheduleList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.CLASSES.SCHEDULE_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.SCHEDULES.LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}