import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { City } from '../../../_common/interfaces/models/city';
import { Country } from '../../../_common/interfaces/models/country';
import { HomeBanner } from '../../../_common/interfaces/models/homeBanner';
import { School } from '../../../_common/interfaces/models/school';
import { State } from '../../../_common/interfaces/models/state';
import { CmsPage } from '../../../_common/interfaces/models/cms';
import { ACTIONS } from '../../../_config'
import { Faq } from '../../../_common/interfaces/models/faq';
import { Notice } from '../../../_common/interfaces/models/notice';
import { Menu } from '../../../_common/interfaces/models/Menu';
import { SiteSettings } from '../../../_common/interfaces/models/siteSettings';

export interface CountryStateCityReducer {
  countryList: Country[];
  stateList: State[];
  cityList: City[];
  highSchools: School[];
  banners: HomeBanner[];
  cmsPages: CmsPage[];
  faqList: Faq[];
  noticeList: Notice[];
  menuList: Menu[];
  siteSettingsList: SiteSettings[];
}

const initialState: CountryStateCityReducer = {
  countryList: [],
  stateList: [],
  cityList: [],
  highSchools: [],
  banners: [],
  cmsPages: [],
  faqList: [],
  noticeList: [],
  menuList: [],
  siteSettingsList: [],
};

const countryStateCityReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.COUNTRY_STATE_CITY.COUNTRY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case ACTIONS.COUNTRY_STATE_CITY.FAQ_LIST:
      return {
        ...state,
        faqList: action.payload,
      };
    case ACTIONS.COUNTRY_STATE_CITY.NOTICES:
      return {
        ...state,
        noticeList: action.payload,
      };
    case ACTIONS.COUNTRY_STATE_CITY.STATE_LIST:
      return {
        ...state,
        stateList: action.payload,
      };
    case ACTIONS.COUNTRY_STATE_CITY.CITY_LIST:
      return {
        ...state,
        cityList: action.payload,
      };
    case ACTIONS.COUNTRY_STATE_CITY.HIGHSCHOOL_LIST:
      return {
        ...state,
        highSchools: action.payload,
      };
    case ACTIONS.COUNTRY_STATE_CITY.BANNERS:
      return {
        ...state,
        banners: action.payload,
      };
    case ACTIONS.COUNTRY_STATE_CITY.CMS_PAGES:
      return {
        ...state,
        cmsPages: action.payload,
      };
    case ACTIONS.COUNTRY_STATE_CITY.RESET_COUNTRY_LIST:
      return {
        ...state,
        countryList: [],
      };
    case ACTIONS.COUNTRY_STATE_CITY.RESET_FAQ_LIST:
      return {
        ...state,
        faqList: [],
      };
    case ACTIONS.COUNTRY_STATE_CITY.RESET_NOTICES:
      return {
        ...state,
        noticeList: [],
      };
    case ACTIONS.COUNTRY_STATE_CITY.RESET_STATE_LIST:
      return {
        ...state,
        stateList: [],
      };
    case ACTIONS.COUNTRY_STATE_CITY.RESET_CITY_LIST:
      return {
        ...state,
        cityList: [],
      };
    case ACTIONS.COUNTRY_STATE_CITY.RESET_HIGHSCHOOL_LIST:
      return {
        ...state,
        highSchools: [],
      };
    case ACTIONS.COUNTRY_STATE_CITY.RESET_BANNERS:
      return {
        ...state,
        banners: [],
      };
    case ACTIONS.COUNTRY_STATE_CITY.RESET_CMS_PAGES:
      return {
        ...state,
        cmsPages: [],
      };
    case ACTIONS.COUNTRY_STATE_CITY.MENUS:
      return {
        ...state,
        menuList: action.payload,
      };
    case ACTIONS.COUNTRY_STATE_CITY.RESET_MENUS:
      return {
        ...state,
        menuList: [],
      };
    case ACTIONS.COUNTRY_STATE_CITY.SITESETTINGS:
      return {
        ...state,
        siteSettingsList: action.payload,
      };
    case ACTIONS.COUNTRY_STATE_CITY.RESET_SITESETTINGS:
      return {
        ...state,
        siteSettingsList: [],
      };
    case ACTIONS.COUNTRY_STATE_CITY.RESET_ALL:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export default countryStateCityReducer;
