import React, { useEffect, useState } from 'react'
import AppModal from '../../_common/components/elements/app-modal/appModal'
import { useAppSignatureAction } from '../../_common/hooks/actions/appSignatureActions'
import { useSignatureApi } from '../../_common/hooks/actions/appSignatureApiHook'
import { useAppSignatureModalShownSelector, useAppSignaturePendingListSelector } from '../../_common/hooks/selectors/signatureSelector'
import { useAppUserAuthSelector } from '../../_common/hooks/selectors/userSelector'
import { Status } from '../../_common/interfaces/models/_common'
import SignatureModalBody from './signatureModalBody'

function AppSignatureRoot() {
  const signatureActions = useAppSignatureAction()
  const isSignModalShown = useAppSignatureModalShownSelector()
  const signatureApi = useSignatureApi()
  const pendingSignatures = useAppSignaturePendingListSelector()
  const isAuth = useAppUserAuthSelector()
  const [ apiCalled, setApiCalled ] = useState(false)
  /**
   * for testing only... remove date once UI implemented
   */
  useEffect(() => {
    if (isAuth && !apiCalled) {
      signatureApi.callGetPendingSignatureList({
        date: '2021-03-30'
      }, () => {}, () => {})
      setApiCalled(true)
    }
    if (!isAuth) {
      signatureActions.setHideModal()
      signatureActions.resetAll();
    }
  }, [])

  useEffect(() => {
    if (pendingSignatures && pendingSignatures.length) {
      let unsignedCount = pendingSignatures.filter(x => x.signature_status ==  Status.inactive || !x.signature).length

      if (unsignedCount) {
        signatureActions.setShowModal();
      }
    }
  }, [pendingSignatures])

  const closeSignatureModal = () => {
    signatureActions.setHideModal()
  }
  return (
    <React.Fragment>
      <AppModal
        shouldShow={isSignModalShown}
        onClose={closeSignatureModal}
        headerTitle="Signatures"
        bodyClass="p-0"
        body={<SignatureModalBody/>}
        hideClose={true}
      />
    </React.Fragment>
  )
}

export default AppSignatureRoot
