import { ACTIONS, SAGA_ACTIONS } from '../../../_config'
import { ServiceDetailsReq, ServiceListReq } from '../../interfaces/ApiReqRes'
import { useApiCall } from './appApiCallHook'

export function useServiceApi() {

  const callApi = useApiCall()

  const getServiceList = (data: ServiceListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SERVICE.LIST, data, onSuccess, onError);
  }
  const getServiceDetails = (data: ServiceDetailsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SERVICE.DETAILS, data, onSuccess, onError);
  }

  return {
    callServiceList: getServiceList,
    callServiceDetails: getServiceDetails,
  }
}