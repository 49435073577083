import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../_config';
import { CallApi } from './api/callApi';

export function* getPackageList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.PACKAGES.LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.PACKAGES.LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      console.log('Here resp error');
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getMyPackageList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.PACKAGES.MY_PACKAGE_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.PACKAGES.MY_PACKAGE_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getPackageTransactionHistory(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.PACKAGES.STUDENT_TRANSACTION_HISTORY, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.PACKAGES.STUDENT_TRANSACTION_HISTORY,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getStudentDueList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.PACKAGES.STUDENT_DUE_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.PACKAGES.STUDENT_DUE_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* doPartialPayment(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.PACKAGES.PARTIAL_PAYMENT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}