import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { Class } from '../../../_common/interfaces/models/class';
import { ClassType } from '../../../_common/interfaces/models/classType';
import { InstructorAvailabilityDate } from '../../../_common/interfaces/models/InstructorAvailabilityDate';
import { MakeupClass } from '../../../_common/interfaces/models/makeupClass';
import { MyClass } from '../../../_common/interfaces/models/myClass';
import { ParentClass } from '../../../_common/interfaces/models/parentClass';
import { Service } from '../../../_common/interfaces/models/service';
import { StudentBTWClass } from '../../../_common/interfaces/models/studentBTWClass';
import { StudentClassCondition } from '../../../_common/interfaces/models/studentClassCondition';
import { ACTIONS } from '../../../_config'

export interface ClassesReducer {
  classList: Class[];
  myClassList: MyClass | null;
  classDetails: Class | null;
  classTypesList: ClassType[];
  instructorAvailability: InstructorAvailabilityDate[];
  btwClassList: StudentBTWClass[];
  makeupClassList: MakeupClass[];
  classCondition: StudentClassCondition | null;
  parentClassList: ParentClass[];
}

const initialState: ClassesReducer = {
  classList: [],
  myClassList: null,
  classDetails: null,
  classTypesList: [],
  instructorAvailability: [],
  btwClassList: [],
  makeupClassList: [],
  classCondition: null,
  parentClassList: [],
};

const classesReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.CLASSES.LIST:
      return {
        ...state,
        classList: action.payload,
      };
    case ACTIONS.CLASSES.CLASS_TYPE_LIST:
      return {
        ...state,
        classTypesList: action.payload,
      };
    case ACTIONS.CLASSES.RESET_LIST:
      return {
        ...state,
        classList: [],
      };
    case ACTIONS.CLASSES.MY_CLASS_LIST:
      return {
        ...state,
        myClassList: action.payload,
      };
    case ACTIONS.CLASSES.RESET_MY_CLASS_LIST:
      return {
        ...state,
        myClassList: [],
      };
    case ACTIONS.CLASSES.MAKEUP_CLASS_LIST:
      return {
        ...state,
        makeupClassList: action.payload,
      };
    case ACTIONS.CLASSES.RESET_MAKEUP_CLASS_LIST:
      return {
        ...state,
        makeupClassList: [],
      };
    case ACTIONS.CLASSES.INSTRUCTOR_AVAILABILITY:
      return {
        ...state,
        instructorAvailability: action.payload,
      };
    case ACTIONS.CLASSES.RESET_INSTRUCTOR_AVAILABILITY:
      return {
        ...state,
        instructorAvailability: [],
      };
    case ACTIONS.CLASSES.BTW_LIST:
      return {
        ...state,
        btwClassList: action.payload,
      };
    case ACTIONS.CLASSES.RESET_BTW_LIST:
      return {
        ...state,
        btwClassList: [],
      };
    case ACTIONS.CLASSES.DETAILS:
      return {
        ...state,
        classDetails: action.payload,
      };
    case ACTIONS.CLASSES.RESET_DETAILS:
      return {
        ...state,
        classDetails: null,
      };
    case ACTIONS.CLASSES.STUDENT_CLASS_CONDITION:
      return {
        ...state,
        classCondition: action.payload,
      };
    case ACTIONS.CLASSES.RESET_STUDENT_CLASS_CONDITION:
      return {
        ...state,
        classCondition: null,
      };
    case ACTIONS.CLASSES.PARENT_CLASS_LIST:
      return {
        ...state,
        parentClassList: action.payload,
      };
    case ACTIONS.CLASSES.RESET_PARENT_CLASS_LIST:
      return {
        ...state,
        parentClassList: [],
      };
    default:
      return state;
  }
};

export default classesReducer;
