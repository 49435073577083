import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../_config';
import { CallApi } from './api/callApi';

export function* getClassesList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.CLASSES.LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.CLASSES.LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getInstructorAvailability(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.CLASSES.INSTRUCTOR_AVAILABILITY, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.CLASSES.INSTRUCTOR_AVAILABILITY,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getClassTypeList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.CLASSES.CLASS_TYPE_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.CLASSES.CLASS_TYPE_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getMakeupClassList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.CLASSES.MAKEUP_CLASS_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.CLASSES.MAKEUP_CLASS_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* bookMakeupClass(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.CLASSES.BOOK_MAKEUP_CLASS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getMyClassesList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.CLASSES.MY_CLASS_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.CLASSES.MY_CLASS_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* applyBTW(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.CLASSES.APPLY_BTW, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* removeBTW(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.CLASSES.REMOVE_BTW_REQUEST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getBTWClassList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.CLASSES.BTW_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.CLASSES.BTW_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getStudentClassCondition(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.CLASSES.STUDENT_CLASS_CONDITION, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.CLASSES.STUDENT_CLASS_CONDITION,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getParentClassList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.CLASSES.PARENT_CLASS_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.CLASSES.PARENT_CLASS_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updateParentClassStatus(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.CLASSES.PARENT_CLASS_UPDATE, data, true);
    if (resp.status >= 200 && resp.status < 300) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
