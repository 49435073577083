import { ACTIONS, SAGA_ACTIONS } from '../../../_config'
import { SignatureUploadReq, SignatureListReq } from '../../interfaces/ApiReqRes'
import { useApiCall } from './appApiCallHook'

export function useSignatureApi() {

  const callApi = useApiCall()

  const getPendingSignatureList = (data: SignatureListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SIGNATURE.FETCH_PENDING_LIST, data, onSuccess, onError);
  }
  const uploadSignature = (data: SignatureUploadReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.SIGNATURE.UPLOAD_SIGNATURE, data, onSuccess, onError);
  }

  return {
    callGetPendingSignatureList: getPendingSignatureList,
    callUploadSignature: uploadSignature,
  }
}