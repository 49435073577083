import React, { useEffect } from 'react'
import { useClassesApi } from '../../_common/hooks/actions/appClassesApiHook'
import { useAppParentClassListSelector } from '../../_common/hooks/selectors/classSelector'
import moment from 'moment'

import './parentModal.css'
import { ParentClass } from '../../_common/interfaces/models/parentClass'
import { useToaster } from '../../_common/hooks/actions/appToasterHook'
import { useAppUserAuthSelector } from '../../_common/hooks/selectors/userSelector'

function AppParentModalBody() {

  const parentClasses = useAppParentClassListSelector()
  const classApi = useClassesApi()
  const toast = useToaster();
  const isLoggedIn = useAppUserAuthSelector()

  const acceptClass = (pc: ParentClass) => {
    classApi.callUpdateParentClass({
      id: pc.id,
      status: 1
    }, (msg: string) => {
      toast.success(msg)
      classApi.callGetParentClassList(() => {}, () => {})
    }, (msg: string) => {
      toast.error(msg)
    })
  }

  const rejectClass = (pc: ParentClass) => {
    classApi.callUpdateParentClass({
      id: pc.id,
      status: 2
    }, (msg: string) => {
      toast.success(msg)
      classApi.callGetParentClassList(() => {}, () => {})
    }, (msg: string) => {
      toast.error(msg)
    })
  }

  if (!parentClasses || !parentClasses.length) {
    return null
  }

  return (
    <React.Fragment>
      <div className="sign-list-container">
        {
          parentClasses && parentClasses.length ? parentClasses.map((pc, index) => {
            return <div className="row sign-list-single" key={index}>
              <div className="col-8 sign-list-info">
                <span className="sign-list-info-session">{pc.class_type_title} ({pc.guardian_name})</span>
                <span className="sign-list-info-time">{moment(`${pc.s_date} ${pc.start_time}`, 'YYYY-MM-DD HH:mm').format('dddd, MMM DD, YYYY / hh:mm A')} - {moment(`${pc.s_date} ${pc.end_time}`, 'YYYY-MM-DD HH:mm').format('hh:mm A')}</span>
              </div>
              <div className="col-2 sign-list-action">
                <button onClick={() => acceptClass(pc)} className="btn btn-success sign-list-action-btn" >Accept</button>
              </div>
              <div className="col-2 sign-list-action">
                <button onClick={() => rejectClass(pc)} className="btn btn-danger sign-list-action-btn" >Reject</button>
              </div>
            </div>
          }) : null
        }
      </div>
    </React.Fragment>
  )
}

export default AppParentModalBody
