import React, { useEffect } from 'react'
import { useAppSignatureIsListViewSelector, useAppSignatureIsSignViewSelector } from '../../_common/hooks/selectors/signatureSelector'
import SignatureInputView from './signatureInputView'
import SignatureListView from './signatureListView'

import './signatureModal.css'

function SignatureModalBody() {
  /**
   * const
   */
  const isSignMode = useAppSignatureIsSignViewSelector()
  const isListMode = useAppSignatureIsListViewSelector()
  /**
   * effects
   */
  /**
   * functions
   */
  /**
   * render functions
   */

  return (
    <div className="container-fluid">
    {
      isListMode ? <SignatureListView/> : null
    }
    {
      isSignMode ? <SignatureInputView/> : null
    }
    </div>
  )
}

export default SignatureModalBody
