import React from 'react'
import { useAppCompanyDetailsSelector } from '../../../hooks/selectors/companySelector'

function TopInfoBar() {
  /**
   * const
   */
  const company = useAppCompanyDetailsSelector()
  /**
   * effects
   */
  return (
    <div className="top-part-header">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="top-text-left">
              <img src="/images/clock-icon.png" alt="" /> {company?.schedule_time}
                    </div>
          </div>
          <div className="col-md-6">
            <div className="top-text-right">
              <img src="/images/location-icon.png" alt="" /> {company?.address}
                    </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopInfoBar
