import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import axios from 'axios';

import { store, persistor } from './store'
import AppLoader from './_common/components/elements/full-page-loader/appLoader';
import { ACTIONS, API_URL, APP_STAGE, STORAGE } from './_config';

import { ToastContainer } from 'react-toastify'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import LoginModal from './components/login/loginModal';
import AppSignatureRoot from './components/signature/appSignatureRoot';
import AppPaymentReminderRoot from './components/paymentReminder/paymentReminderRoot';
import AppParentModalRoot from './components/parent-modal/appParentModalRoot';

function render() {
  ReactDOM.render(
    <React.Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.Fragment>
            <AppLoader />
            <App />
            <AppSignatureRoot/>
            <AppParentModalRoot/>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </React.Fragment>
        </PersistGate>
      </Provider>
    </React.Fragment>,
    document.getElementById('root')
  );
}

const token = localStorage.getItem(STORAGE);

if (token) {
  try {
    axios({
      method: 'GET',
      url: API_URL.USER.DETAILS,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(success => {
        store.dispatch({
          type: ACTIONS.USER.LOGIN,
          payload: {
            token: token,
            user: success.data.data
          },
        });
        render();
      })
      .catch(error => {
        localStorage.removeItem(STORAGE);
        store.dispatch({
          type: ACTIONS.USER.LOGOUT,
        });
        render();
      });
  } catch (e) {
    localStorage.removeItem(STORAGE);
    render();
  }
} else {
  render();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (APP_STAGE === 'prod') {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}

