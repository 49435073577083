import React from 'react';

const LazyTermsCondition = React.lazy(() =>
  import(/* webpackChunkName: "TermsCondition" */ './termsCondition')
);

const TermsCondition = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyTermsCondition {...props} />
  </React.Suspense>
);

export default TermsCondition