import React from 'react';

const LazyContact = React.lazy(() =>
  import(/* webpackChunkName: "Contact" */ './contactus')
);

const Contact = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyContact {...props} />
  </React.Suspense>
);

export default Contact