import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { MyPackage } from '../../../_common/interfaces/models/myPackage';
import { Package } from '../../../_common/interfaces/models/package';
import { PackageTransaction } from '../../../_common/interfaces/models/packageTransaction';
import { Service } from '../../../_common/interfaces/models/service';
import { StudentDueAmount } from '../../../_common/interfaces/models/studentDueAmount';
import { ACTIONS } from '../../../_config'

export interface PackageReducer {
  packageList: Package[];
  myPackageList: MyPackage[];
  packageDetails: Package | null;
  transactionHistory: PackageTransaction[];
  studentDueList: StudentDueAmount[];
  paymentReminderModalShown: boolean;
}

const initialState: PackageReducer = {
  packageList: [],
  myPackageList: [],
  transactionHistory: [],
  packageDetails: null,
  studentDueList: [],
  paymentReminderModalShown: false,
};

const packageReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.PACKAGES.LIST:
      return {
        ...state,
        packageList: action.payload,
      };
    case ACTIONS.PACKAGES.RESET_LIST:
      return {
        ...state,
        packageList: [],
      };
    case ACTIONS.PACKAGES.STUDENT_DUE_LIST:
      return {
        ...state,
        studentDueList: action.payload,
      };
    case ACTIONS.PACKAGES.RESET_STUDENT_DUE_LIST:
      return {
        ...state,
        studentDueList: [],
      };
    case ACTIONS.PACKAGES.SET_PAYMENT_REMINDER_MODAL_STATUS:
      return {
        ...state,
        paymentReminderModalShown: action.payload,
      };
    case ACTIONS.PACKAGES.STUDENT_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: action.payload,
      };
    case ACTIONS.PACKAGES.RESET_STUDENT_TRANSACTION_HISTORY:
      return {
        ...state,
        transactionHistory: [],
      };
    case ACTIONS.PACKAGES.MY_PACKAGE_LIST:
      return {
        ...state,
        myPackageList: action.payload,
      };
    case ACTIONS.PACKAGES.RESET_MY_PACKAGE_LIST:
      return {
        ...state,
        myPackageList: [],
      };
    case ACTIONS.PACKAGES.PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: action.payload,
      };
    case ACTIONS.PACKAGES.RESET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: null,
      };
    default:
      return state;
  }
};

export default packageReducer;
