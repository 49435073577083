import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../_config';
import { CallApi } from './api/callApi';

export function* getSignaturePendingList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.SIGNATURE.FETCH_PENDING_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.SIGNATURE.FETCH_PENDING_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* submitSignature(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.SIGNATURE.SUBMIT_NEW, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: SAGA_ACTIONS.SIGNATURE.FETCH_PENDING_LIST,
        payload: null,
        callbackSuccess: () => {
          action && action.callbackSuccess && action.callbackSuccess(resp.data);
        },
        callbackError: () => {
          action && action.callbackSuccess && action.callbackSuccess(resp.data);
        },
      })
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}