import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { NOTICE_CONFIG, URLS } from '../../../../_config'
import { useStaticApi } from '../../../hooks/actions/appStaticApiHook'
import { useToaster } from '../../../hooks/actions/appToasterHook'
import { useAppCompanyDetailsSelector } from '../../../hooks/selectors/companySelector'
import { useAppNoticeListSelector } from '../../../hooks/selectors/staticSelector'

import './notice.css'

function AppNotice() {
  /**
   * const
   */
  const [ showNotice, setShowNotice ] = useState(false)
  const [ urlSlug, setUrlSlug ] = useState<string | null>('')
  const [ noticeText, setNoticeText ] = useState<string | null>('')
  const history = useHistory()
  const staticApi = useStaticApi()
  const toast = useToaster()
  const noticeList = useAppNoticeListSelector()
  const noticeConfigKeys = Object.keys(NOTICE_CONFIG)
  const companyDetails = useAppCompanyDetailsSelector()
  /**
   * effects
   */
  useEffect(() => {
    callNoticeGetAll()
  }, [companyDetails])
  useEffect(() => {
    if (history) {
      let path = history.location.pathname
      let find = noticeConfigKeys.findIndex(x => x.startsWith(path))
      if (find >= 0) {
        setUrlSlug(NOTICE_CONFIG[noticeConfigKeys[find]])
      } else {
        if (path.startsWith(URLS.SERVICES_ONLY)) {
          path = path.slice(0, path.lastIndexOf('/'))
          find = noticeConfigKeys.findIndex(x => x.startsWith(path))
          if (find >= 0) {
            setUrlSlug(NOTICE_CONFIG[noticeConfigKeys[find]])
          } else {
            setUrlSlug(null)
          }
        } else {
          setUrlSlug(null)
        }
      }
    } else {
      setUrlSlug(null)
    }
  }, [history])
  useEffect(() => {
    if (noticeList && urlSlug) {
      checkIfShouldShowNotice()
    } else {
      setShowNotice(false)
    }
  }, [noticeList, urlSlug])
  useEffect(() => {
    if (!showNotice) {
      setNoticeText(null)
    }
  }, [showNotice])
  /**
   * functions
   */
  const callNoticeGetAll = () => {
    if (!companyDetails) {
      return
    }
    staticApi.callGetNoticeList({
      company_id: companyDetails.id,
    }, () => {}, (message: string) => {
    })
  }
  const checkIfShouldShowNotice = () => {
    if (!noticeList || !noticeList.length || !urlSlug) {
      setShowNotice(false)
      return
    }
    let find = noticeList.find(n => n.page_slug == urlSlug)
    if (find && find.description) {
      setShowNotice(true)
      setNoticeText(find.description)
    } else {
      setShowNotice(false)
    }
  }
  /**
   * render functions
   */
  if (!showNotice || !noticeText) {
    return null
  }
  return (
    <div className="info-wrap app-notice" dangerouslySetInnerHTML={{__html: noticeText}}>
    </div>
  )
}

export default AppNotice
