import React from 'react';

const LazyLocation = React.lazy(() =>
  import(/* webpackChunkName: "Location" */ './locations')
);

const Location = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyLocation {...props} />
  </React.Suspense>
);

export default Location