import React, { useEffect, useState } from 'react'
import { useAppSignatureAction } from '../../_common/hooks/actions/appSignatureActions'
import { useSignatureApi } from '../../_common/hooks/actions/appSignatureApiHook'
import { useToaster } from '../../_common/hooks/actions/appToasterHook'
import { useAppSignaturePendingListSelector } from '../../_common/hooks/selectors/signatureSelector'
import { PendingSignature } from '../../_common/interfaces/models/pendingSignature'
import { Status } from '../../_common/interfaces/models/_common'

import './signatureModal.css'

function SignatureListView() {
  /**
   * const
   */
  const signatureActions = useAppSignatureAction()
  const signatureApi = useSignatureApi()
  const pendingSignatures = useAppSignaturePendingListSelector()
  const toast = useToaster();
  /**
   * effects
   */
  useEffect(() => {
    signatureApi.callGetPendingSignatureList({
      date: '2021-03-30'
    }, () => {}, (message: string) => {
      toast.error(message)
      signatureActions.setHideModal();
    })
  }, [])
  useEffect(() => {
    if (pendingSignatures && pendingSignatures.length) {
      let unsignedCount = pendingSignatures.filter(x => x.signature_status ==  Status.inactive || !x.signature).length

      if (!unsignedCount) {
        signatureActions.setHideModal();
      }
    }
  }, [pendingSignatures])
  /**
   * functions
   */
  const signSession = (sig: PendingSignature) => {
    signatureActions.setSelected(sig);
    signatureActions.setListSignMode();
  }
  /**
   * render functions
   */
  return (
    <React.Fragment>
      <div className="sign-list-container">
        {
          pendingSignatures && pendingSignatures.length ? pendingSignatures.filter(x => x.signature_status == Status.inactive).map((sig, index) => {
            return <div className="row sign-list-single" key={index}>
              <div className="col-8 sign-list-info">
                <span className="sign-list-info-session">{sig.title}</span>
                <span className="sign-list-info-time">{sig.formattedDateString}</span>
              </div>
              <div className="col-4 sign-list-action">
                <button onClick={() => signSession(sig)} className="btn btn-success sign-list-action-btn" >Sign</button>
              </div>
            </div>
          }) : null
        }
      </div>
      <div className="sign-label-container">
        <span className="sign-label-header">Signature is required</span>
        <span className="sign-label-sub-title">You cannot close this window until you have signed all the sessions.</span>
      </div>
    </React.Fragment>
  )
}

export default SignatureListView
