import React from 'react';

const LazyMyCalendar = React.lazy(() =>
  import(/* webpackChunkName: "MyCalendarInstructor" */ './mycalendar')
);

const MyCalendar = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyMyCalendar {...props} />
  </React.Suspense>
);

export default MyCalendar