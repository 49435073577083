import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Company } from '../../interfaces/models/company'
import { PayCard } from '../../interfaces/models/payCard'
import { User } from '../../interfaces/models/user'
import { StateExtended } from '../../interfaces/StateExtended'


export function useAppUserDetailsSelector() {
  const user: User | null = useSelector((state: StateExtended) => state.user.user)

  return user
}

export function useAppUserTokenSelector() {
  const token: string | null = useSelector((state: StateExtended) => state.user.token)

  return token
}

export function useAppUserAuthSelector() {
  const isAuth: boolean = useSelector((state: StateExtended) => state.user.isAuthenticated)

  return isAuth
}

export function useAppUserCardListSelector() {
  const list: PayCard[] = useSelector((state: StateExtended) => state.user.cardList)

  return list
}