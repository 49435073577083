import React from 'react'
import { useAppPackageStudentDueListSelector } from '../../_common/hooks/selectors/packageSelector'
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router';
import { URLS } from '../../_config';

import './paymentReminder.css'

interface PaymentReminderModalBodyProps {
  onClose: () => void;
}

function PaymentReminderModalBody({
  onClose,
}: PaymentReminderModalBodyProps) {

  const dues = useAppPackageStudentDueListSelector()
  const history = useHistory()
  
  const onClickPay = () => {
    if (history && history.location.pathname == URLS.STUDENT.MY_PACKAGES) {
      onClose()
    } else {
      history.push(URLS.STUDENT.MY_PACKAGES)
      onClose()
    }
  }
  return (
    <div className="container-fluid">
      <div className="payment-reminder-container">
      {
        dues.map((due, index) => {
          return <div key={index} className="row mb-2 payment-reminder-row-single">
            <div className="col-8">{due.class_details.title || 'Class name'}</div>
            <div className="col-4">
            <NumberFormat fixedDecimalScale decimalScale={2} value={due.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </div>
          </div>
        })
      }
      <div className="row mb-2 mt-2 pt-2 payment-reminder-row-action">
        <div className="col-12 d-flex justify-content-center">
          <button onClick={onClickPay} className="btn btn-success">Pay due amount</button>
        </div>
      </div>
      </div>
    </div>
  )
}

export default PaymentReminderModalBody
