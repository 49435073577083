import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Class } from '../../interfaces/models/class'
import { Company } from '../../interfaces/models/company'
import { StateExtended } from '../../interfaces/StateExtended'


export function useAppCompanyDetailsSelector() {
  const data: Company | null = useSelector((state: StateExtended) => state.company.details)

  return data
}