import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { RegisterAndBuyReq } from '../../../_common/interfaces/ApiReqRes';
import { Class } from '../../../_common/interfaces/models/class';
import { Package } from '../../../_common/interfaces/models/package';
import { Service } from '../../../_common/interfaces/models/service';
import { ACTIONS } from '../../../_config'

export enum RegistrationStep {
  SERVICE_SELECTION = 1,
  PACKAGE_SELECTION = 2,
  CLASS_SELECTION = 3, 
  STUDENT_INFO = 4,
  PAYMENT = 5
}

export interface StudentRegistrationInfo extends Omit<RegisterAndBuyReq, 'is_saved' | 'gateway_id' | 'is_default' | 'card_name' | 'card_number' | 'expiry_month' | 'expiry_year' | 'card_code' | 'amount'> {}

export interface UserDob {
  year: number;
  month: number;
  day: number;
}

export interface RegistrationReducer {
  selectedPackage: Package | null;
  selectedService: Service | null;
  selectedClass: Class | null;
  userDob: UserDob;
  currentStep: RegistrationStep;
  shouldShowPackages: boolean;
  studentInfo: StudentRegistrationInfo | null;
}

const initialState: RegistrationReducer = {
  selectedService: null,
  selectedPackage: null,
  selectedClass: null,
  userDob: {
    day: 0,
    month: 0,
    year: 0,
  },
  currentStep: RegistrationStep.SERVICE_SELECTION,
  shouldShowPackages: false,
  studentInfo: null,
};

const registrationReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.REGISTRATION.SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      }
    case ACTIONS.REGISTRATION.RESET_STEP:
      return {
        ...state,
        currentStep: RegistrationStep.SERVICE_SELECTION,
      }
    case ACTIONS.REGISTRATION.SELECT_SERVICE:
      return {
        ...state,
        selectedService: action.payload,
      };
    case ACTIONS.REGISTRATION.SELECT_PACKAGE:
      return {
        ...state,
        selectedPackage: action.payload,
      };
    case ACTIONS.REGISTRATION.SELECT_CLASS:
      return {
        ...state,
        selectedClass: action.payload,
      };
    case ACTIONS.REGISTRATION.SET_STUDENT_INFO:
      return {
        ...state,
        studentInfo: action.payload,
      };
    case ACTIONS.REGISTRATION.RESET_STUDENT_INFO:
      return {
        ...state,
        studentInfo: null,
      };
    case ACTIONS.REGISTRATION.RESET_SELECT_SERVICE:
      return {
        ...state,
        selectedService: null,
      };
    case ACTIONS.REGISTRATION.RESET_SELECT_PACKAGE:
      return {
        ...state,
        selectedPackage: null,
      };
    case ACTIONS.REGISTRATION.RESET_SELECT_CLASS:
      return {
        ...state,
        selectedClass: null,
      };
    case ACTIONS.REGISTRATION.SELECT_DOB.ALL:
      return {
        ...state,
        userDob: action.payload,
      }
    case ACTIONS.REGISTRATION.RESET_ALL_DOB:
      return {
        ...state,
        userDob: {
          day: 0,
          month: 0,
          year: 0,
        },
        shouldShowPackages: false,
      }
    case ACTIONS.REGISTRATION.SELECT_DOB.YEAR:
      return {
        ...state,
        userDob: {
          ...state.userDob,
          year: action.payload,
        }
      };
    case ACTIONS.REGISTRATION.SELECT_DOB.MONTH:
      return {
        ...state,
        userDob: {
          ...state.userDob,
          month: action.payload,
        }
      };
    case ACTIONS.REGISTRATION.SELECT_DOB.DAY:
      return {
        ...state,
        userDob: {
          ...state.userDob,
          day: action.payload,
        }
      };
    case ACTIONS.REGISTRATION.SET_SHOW_PACKAGE:
      return {
        ...state,
        shouldShowPackages: action.payload,
      }
    case ACTIONS.REGISTRATION.RESET_ALL:
      return {
        ...state,
        selectedService: null,
        selectedPackage: null,
        selectedClass: null,
        userDob: {
          day: 0,
          month: 0,
          year: 0,
        },
        currentStep: RegistrationStep.SERVICE_SELECTION,
        shouldShowPackages: false,
        studentInfo: null,
      };
    default:
      return state;
  }
};

export default registrationReducer;
