import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Class } from '../../interfaces/models/class'
import { ClassType } from '../../interfaces/models/classType'
import { InstructorAvailabilityDate } from '../../interfaces/models/InstructorAvailabilityDate'
import { MakeupClass } from '../../interfaces/models/makeupClass'
import { MyClass } from '../../interfaces/models/myClass'
import { ParentClass } from '../../interfaces/models/parentClass'
import { StudentBTWClass } from '../../interfaces/models/studentBTWClass'
import { StudentClassCondition } from '../../interfaces/models/studentClassCondition'
import { StateExtended } from '../../interfaces/StateExtended'


export function useAppClassListSelector() {
  const list: Class[] = useSelector((state: StateExtended) => state.classes.classList)

  return list
}

export function useAppMyClassListSelector() {
  const list: MyClass | null = useSelector((state: StateExtended) => state.classes.myClassList)

  return list
}
export function useAppClassDetailsSelector() {
  const details: Class | null = useSelector((state: StateExtended) => state.classes.classDetails)

  return details
}
export function useAppClassTypeListSelector() {
  const list: ClassType[] = useSelector((state: StateExtended) => state.classes.classTypesList)

  return list
}
export function useAppClassInstructorAvailabilitySelector() {
  const list: InstructorAvailabilityDate[] = useSelector((state: StateExtended) => state.classes.instructorAvailability)

  return list
}
export function useAppClassBTWListSelector() {
  const list: StudentBTWClass[] = useSelector((state: StateExtended) => state.classes.btwClassList)

  return list
}
export function useAppClassConditionSelector() {
  const data: StudentClassCondition | null = useSelector((state: StateExtended) => state.classes.classCondition)

  return data
}
export function useAppMakeupClassListSelector() {
  const list: MakeupClass[] = useSelector((state: StateExtended) => state.classes.makeupClassList)

  return list
}
export function useAppParentClassListSelector() {
  const list: ParentClass[] = useSelector((state: StateExtended) => state.classes.parentClassList)

  return list
}