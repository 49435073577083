import React, { useEffect, useState } from 'react'
import { useAppGlobalAction } from '../../../hooks/actions/appGlobalActionHook'
import { Link } from 'react-router-dom'
import { GODADDY_LINK, MASTER_CARD_LINK, TWITTER_LINK, URLS, VISA_LINK } from '../../../../_config'
import { useAppCompanyDetailsSelector } from '../../../hooks/selectors/companySelector'
import { useStaticApi } from '../../../hooks/actions/appStaticApiHook'
import { useAppMenuListSelector } from '../../../hooks/selectors/staticSelector'
import { useToaster } from '../../../hooks/actions/appToasterHook'

function Footer() {
  const company = useAppCompanyDetailsSelector();
  const menuList = useAppMenuListSelector();
  const staticApi = useStaticApi()
  const toast = useToaster()
  var footerMenu = "";
  useEffect(() => {
    if (company) {
      staticApi.callGetMenuList({
        company_id: company.id,
      }, () => {}, (message: string) => {
        toast.error(message)
      })
    }
  }, [company])
  // console.log(menuList);

  return (
    <footer className="footer">
      <div className="call-us-wrap">
        <div className="container" dangerouslySetInnerHTML={{__html: company && company.footer_text ? company.footer_text : ''}}>
        </div>
      </div>
      <div className="bottom-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="foot-links">
                <Link to={URLS.HOME}>Home</Link>
                {/* <Link to={URLS.ABOUT_US}>About</Link>|<Link to={URLS.LOCATIONS}>Locations</Link>|<Link to={URLS.TWITTER_HELP}>Twitter
                                Help</Link>|<Link to={URLS.FAQ}>FAQS</Link>|<Link to={URLS.CONTACT_US}>Contact</Link> */}
                {
                  menuList?.map((menu, index) => {
                    if(menu.status == 0 || menu.type == 1){
                      return null;
                    }else{
                      return <Link className="footer-links" to={`${menu.link}`}>{menu.name}</Link>;
                    }
                  })
                }
                {footerMenu}
              </div>
              <div className="copy-right-text">
                © {company?.Copy_right_text}
                        </div>
            </div>
            <div className="col-md-6 text-right">
              <div className="card-logos">
                <a href={VISA_LINK} target="_blank" rel="noreferrer"><img src="/images/visa.png" alt="" /></a>
                <a href={MASTER_CARD_LINK} target="_blank" rel="noreferrer"><img src="/images/master-card.png" alt="" /></a>
                <a href={GODADDY_LINK} target="_blank" rel="noreferrer"><img src="/images/godaddy.png" alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer
