import React, { PropsWithChildren } from 'react'
import Footer from '../components/elements/layout-elements/footer'
import AppNotice from '../components/elements/layout-elements/notice'
import TopInfoBar from '../components/elements/layout-elements/topInfoBar'
import TopLogoBar from '../components/elements/layout-elements/topLogoBar'
import TopMenu from '../components/menu/topMenu'

import './Layout.css'

export default function AuthLayout(props: PropsWithChildren<any>) {
  return (
    <React.Fragment>
      <TopInfoBar/>
      <TopLogoBar/>
      <TopMenu/>
      <AppNotice/>
      <div className="main-content-wrapper">
      {props.children}
      </div>
      <Footer/>
    </React.Fragment>
  )
}
