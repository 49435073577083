import moment from 'moment'

export const generateDaysOption = (days: number) => {
  return new Array(days).fill(1).map((x, i) => ({
    value: i + 1,
    label: String(i + 1)
  }))
}

export const generateYearsOption = (numYears: number, ignoreLastYears: number) => {
  return new Array(numYears).fill(1).map((y, i) => {
    let yt = moment().subtract((i + ignoreLastYears), 'year').format('YYYY')
    return {
      value: parseInt(yt),
      label: yt,
    }
  })
}

export const extractErrorMessage = (error: any, defaultMessage = 'Please try again') => {
  if (typeof error === 'string') {
    return error
  } else {
    if (error && error.data && error.data.errors && Object.keys(error.data.errors).length) {
      return error.data.errors[Object.keys(error.data.errors)[0]];
    } else if (error && error.data && error.data.msg) {
      return error.data.msg
    } else if (error && error.msg) {
      return error.msg
    } else {
      return defaultMessage
    }
  }
}

export const generateLink = (link: string) => {
  return `${window.location.origin}${link}/`
}

export const getRandomName = (prefix: string = '', suffix: string = '') => {
  let random = `${new Date().getTime()}_${Math.ceil(Math.random() * 1000)}`
  return `${prefix}${random}${suffix}`
}

export const extractHtmlString = (str: string) => {
  return str ? str.replace(/<[^>]+>/g, '') : ''
}

export const trimTo = (str: string, num: number, appendDots: boolean = false) => {
  return str && str.length > num ? appendDots ? `${str.substr(0, num)}...` : str.substr(0, num) : str
}

export const getYearString = (month: number) => {
  if (!month) {
    return 'N/A'
  }
  let year = Math.floor(month / 12)
  let remMonth = month % 12

  let str = ''
  if (year) {
    str += `${year} ${year > 1 ? 'years' : 'year'}`
  }
  if (month) {
    if (str && str.length) {
      str += ' and '
    }
    str += `${remMonth} ${remMonth > 1 ? 'months' : 'month'}`
  }
  return str;
}

export const getFileFromDataUrl = (dataUrl: string) => {
  if (!dataUrl) {
    return null
  }
  let arr = dataUrl.split(',');
  let mime = null
  if (arr && arr.length) {
    let parts = arr[0].match(/:(.*?);/)
    mime = parts && parts.length ? parts[1] : null
  }
  if (!mime) {
    return null
  }
  let bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], getRandomName('image', `.${mime.split('/')[1]}`), { type: mime });
}