import { useSelector } from 'react-redux'
import { SignatureModalMode } from '../../../store/reducers/common/signatureReducer'
import { StateExtended } from '../../interfaces/StateExtended'


export function useAppSignaturePendingListSelector() {
  const list = useSelector((state: StateExtended) => state.signature.list)
  return list
}

export function useAppSelectedSignatureSelector() {
  const selected = useSelector((state: StateExtended) => state.signature.selected)
  return selected
}

export function useAppSignatureModalShownSelector() {
  const isShown: boolean = useSelector((state: StateExtended) => state.signature.isShown)

  return isShown
}

export function useAppSignatureIsListViewSelector() {
  const isMode: boolean = useSelector((state: StateExtended) => state.signature.mode == SignatureModalMode.list)

  return isMode
}

export function useAppSignatureIsSignViewSelector() {
  const isMode: boolean = useSelector((state: StateExtended) => state.signature.mode == SignatureModalMode.sign)

  return isMode
}