import React from 'react';

const LazyPrivacyPolicy = React.lazy(() =>
  import(/* webpackChunkName: "PrivacyPolicy" */ './privacyPolicy')
);

const PrivacyPolicy = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyPrivacyPolicy {...props} />
  </React.Suspense>
);

export default PrivacyPolicy