import { fork, take, takeLatest, all, takeEvery, takeLeading } from 'redux-saga/effects';
import { SAGA_ACTIONS } from '../_config';

import {
  loginUser,
  getUserProfile,
  logoutUser,
  registerWithPackage,
  updateProfile,
  updateUserProfilePic,
  forgotPassword,
  changePassword,
  forgotPasswordVerifyToken,
  resetPassword,
  getStudentDueAmount,
  getCardList,
  removeCard,
} from './userSaga'

import {
  getCompanyDetails,
} from './companySaga'

import {
  getClassesList,
  getMyClassesList,
  getClassTypeList,
  getInstructorAvailability,
  applyBTW,
  getBTWClassList,
  removeBTW,
  getStudentClassCondition,
  bookMakeupClass,
  getMakeupClassList,
  getParentClassList,
  updateParentClassStatus
} from './classesSaga'

import {
  getPackageList,
  getMyPackageList,
  getPackageTransactionHistory,
  doPartialPayment,
  getStudentDueList,
} from './packageSaga'

import {
  getScheduleList,
} from './scheduleSaga'

import {
  getServiceList,
  getServiceDetails,
} from './serviceSaga'

import {
  getCountryList,
  getCityList,
  getStateList,
  getSchoolList,
  getBannersList,
  getCmsPagesList,
  getFaqList,
  postContactUs,
  getNoticeList,
  getMenuList,
  getSiteSettingsList,
} from './staticSaga'

import {
  getInstructorAppointmentList,
  getInstructorClassStudentList,
  getInstructorPackageList,
  addUpdateInstructorClassNote,
  removeInstructorClassNote,
  addUpdateInstructorStudentNote,
  removeInstructorStudentNote,
  getInstructorAppointmentDetails,
  getInstructorAvailabilityList,
  getInstructorVacationList,
  addInstructorVacation,
  updateInstructorVacation,
  removeInstructorVacation,
} from './instructorSaga'

import {
  getSignaturePendingList,
  submitSignature,
} from './signatureSaga'

export function* sagas() {
  yield all([
    // user saga
    takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
    takeLatest(SAGA_ACTIONS.USER.DETAILS, getUserProfile),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, logoutUser),
    takeLatest(SAGA_ACTIONS.USER.REGISTRATION_AND_BUY, registerWithPackage),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE, updateProfile),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_PROFILE_PIC, updateUserProfilePic),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, forgotPassword),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD_VERIFY_TOKEN, forgotPasswordVerifyToken),
    takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, resetPassword),
    takeLatest(SAGA_ACTIONS.USER.CHANGE_PASSWORD, changePassword),
    takeLatest(SAGA_ACTIONS.USER.STUDENT_AMOUNT_DUE, getStudentDueAmount),
    takeLatest(SAGA_ACTIONS.USER.CARD_LIST, getCardList),
    takeLatest(SAGA_ACTIONS.USER.REMOVE_CARD, removeCard),

    // company saga
    takeLatest(SAGA_ACTIONS.COMPANY.DETAILS, getCompanyDetails),

    // service saga
    takeLeading(SAGA_ACTIONS.SERVICE.LIST, getServiceList),
    takeLatest(SAGA_ACTIONS.SERVICE.DETAILS, getServiceDetails),

    // package saga
    takeLeading(SAGA_ACTIONS.PACKAGE.LIST, getPackageList),
    takeLatest(SAGA_ACTIONS.PACKAGE.MY_PACKAGE_LIST, getMyPackageList),
    takeLatest(SAGA_ACTIONS.PACKAGE.STUDENT_TRANSACTION_HISTORY, getPackageTransactionHistory),
    takeLatest(SAGA_ACTIONS.PACKAGE.PARTIAL_PAYMENT, doPartialPayment),
    takeLatest(SAGA_ACTIONS.PACKAGE.STUDENT_DUE_LIST, getStudentDueList),

    // classes saga
    takeLatest(SAGA_ACTIONS.CLASSES.LIST, getClassesList),
    takeLatest(SAGA_ACTIONS.CLASSES.MY_CLASS_LIST, getMyClassesList),
    takeLatest(SAGA_ACTIONS.CLASSES.CLASS_TYPE_LIST, getClassTypeList),
    takeLatest(SAGA_ACTIONS.CLASSES.INSTRUCTOR_AVAILABILITY, getInstructorAvailability),
    takeLatest(SAGA_ACTIONS.CLASSES.BTW_LIST, getBTWClassList),
    takeLatest(SAGA_ACTIONS.CLASSES.APPLY_BTW, applyBTW),
    takeLatest(SAGA_ACTIONS.CLASSES.REMOVE_BTW_REQUEST, removeBTW),
    takeLatest(SAGA_ACTIONS.CLASSES.STUDENT_CLASS_CONDITION, getStudentClassCondition),
    takeLatest(SAGA_ACTIONS.CLASSES.MAKEUP_CLASS_LIST, getMakeupClassList),
    takeLatest(SAGA_ACTIONS.CLASSES.BOOK_MAKEUP_CLASS, bookMakeupClass),
    takeLatest(SAGA_ACTIONS.CLASSES.PARENT_CLASS_LIST, getParentClassList),
    takeLatest(SAGA_ACTIONS.CLASSES.PARENT_CLASS_UPDATE, updateParentClassStatus),

    // schedule saga
    takeLatest(SAGA_ACTIONS.SCHEDULE.LIST, getScheduleList),

    // static list saga
    takeLatest(SAGA_ACTIONS.COUNTRY_STATE_CITY.COUNTRY_LIST, getCountryList),
    takeLatest(SAGA_ACTIONS.COUNTRY_STATE_CITY.STATE_LIST, getStateList),
    takeLatest(SAGA_ACTIONS.COUNTRY_STATE_CITY.CITY_LIST, getCityList),
    takeLatest(SAGA_ACTIONS.COUNTRY_STATE_CITY.HIGHSCHOOL_LIST, getSchoolList),
    takeLeading(SAGA_ACTIONS.COUNTRY_STATE_CITY.BANNERS, getBannersList),
    takeLatest(SAGA_ACTIONS.COUNTRY_STATE_CITY.CMS_PAGES, getCmsPagesList),
    takeLatest(SAGA_ACTIONS.COUNTRY_STATE_CITY.FAQ_LIST, getFaqList),
    takeLatest(SAGA_ACTIONS.COUNTRY_STATE_CITY.NOTICES, getNoticeList),
    takeLatest(SAGA_ACTIONS.COUNTRY_STATE_CITY.CONTACT_US, postContactUs),
    takeLatest(SAGA_ACTIONS.COUNTRY_STATE_CITY.MENUS, getMenuList),
    takeLatest(SAGA_ACTIONS.COUNTRY_STATE_CITY.SITESETTINGS, getSiteSettingsList),

    // instructor saga
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.APPOINTMENT_LIST,  getInstructorAppointmentList),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.APPOINTMENT_DETAILS,  getInstructorAppointmentDetails),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.CLASS_STUDENT_LIST,  getInstructorClassStudentList),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.BOOKING_PACKAGE_LIST,  getInstructorPackageList),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.ADD_UPDATE_CLASS_NOTE,  addUpdateInstructorClassNote),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.REMOVE_CLASS_NOTE,  removeInstructorClassNote),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.ADD_UPDATE_CLASS_STUDENT_NOTE,  addUpdateInstructorStudentNote),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.REMOVE_CLASS_STUDENT_NOTE,  removeInstructorStudentNote),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.AVAILABILITY, getInstructorAvailabilityList),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.VACATION_LIST, getInstructorVacationList),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.VACATION_ADD, addInstructorVacation),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.VACATION_UPDATE, updateInstructorVacation),
    takeLatest(SAGA_ACTIONS.INSTRUCTOR.VACATION_REMOVE, removeInstructorVacation),

    // signature saga
    takeLatest(SAGA_ACTIONS.SIGNATURE.FETCH_PENDING_LIST, getSignaturePendingList),
    takeLatest(SAGA_ACTIONS.SIGNATURE.UPLOAD_SIGNATURE, submitSignature),
  ]);
}