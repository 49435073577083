import { ACTIONS, SAGA_ACTIONS } from '../../../_config'
import { MyPackageListReq, PackageListReq, PackagePartialPaymentReq, PackageTransactionListReq } from '../../interfaces/ApiReqRes'
import { useApiCall } from './appApiCallHook'

export function usePackageApi() {

  const callApi = useApiCall()

  const getPackageList = (data: PackageListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PACKAGE.LIST, data, onSuccess, onError);
  }
  const getMyPackageList = (data: MyPackageListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PACKAGE.MY_PACKAGE_LIST, data, onSuccess, onError);
  }
  const getPackageDetails = (data: MyPackageListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PACKAGE.PACKAGE_DETAILS, data, onSuccess, onError);
  }
  const getPackageTransactionHistory = (data: PackageTransactionListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PACKAGE.STUDENT_TRANSACTION_HISTORY, data, onSuccess, onError);
  }
  const doPartialPayment = (data: PackagePartialPaymentReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PACKAGE.PARTIAL_PAYMENT, data, onSuccess, onError);
  }
  const getStudentDueList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PACKAGE.STUDENT_DUE_LIST, null, onSuccess, onError);
  }

  return {
    callPackageList: getPackageList,
    callMyPackageList: getMyPackageList,
    callPackageDetails: getPackageDetails,
    callGetPackageTransactionHistory: getPackageTransactionHistory,
    callDoPartialPayment: doPartialPayment,
    callGetStudentDueList: getStudentDueList,
  }
}