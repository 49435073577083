import { func } from 'prop-types'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import AppModal from '../../_common/components/elements/app-modal/appModal'
import { useAppGlobalAction } from '../../_common/hooks/actions/appGlobalActionHook'
import { useAppGlobalLoginModalShownSelector } from '../../_common/hooks/selectors/globalSelector'
import { URLS } from '../../_config'
import LoginForm from './loginForm'
import LoginSocial from './loginSocial'

function LoginModalFooter() {
  const globalActions = useAppGlobalAction()
  const history = useHistory()

  const gotoRegister = (evt: React.MouseEvent) => {
    evt && evt.preventDefault()
    globalActions.hideLoginModal()
    history.push(URLS.REGISTRATION)
  }

  return (
    <div className="d-flex justify-content-center flex-grow-1">
      <div className="signup-section">Not a member yet? <a href="#" onClick={gotoRegister}> Sign Up</a>.</div>
    </div>
  )
}

function LoginModal() {
  /**
   * const
   */
  const loginModalShown = useAppGlobalLoginModalShownSelector()
  const globalActions = useAppGlobalAction()
  /**
   * effects
   */
  /**
   * functions
   */
  const closeLoginModal = () => {
    globalActions.hideLoginModal()
  }
  return (
    <div>
      <AppModal
        shouldShow={loginModalShown}
        headerTitle="Login"
        body={<>
          <LoginForm from="modal" />
          <div className="text-center text-muted delimiter">or use a social network</div>
          <LoginSocial/>
        </>}
        footer={<LoginModalFooter/>}
        onClose={closeLoginModal}
      />
    </div>
  )
}

export default LoginModal
