import { ACTIONS, SAGA_ACTIONS } from '../../../_config'
import { CompanyDetailsReq, LoginReq } from '../../interfaces/ApiReqRes'
import { useApiCall } from './appApiCallHook'

export function useCompanyApi() {

  const callApi = useApiCall()

  const getDetails = (data: CompanyDetailsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMPANY.DETAILS, data, onSuccess, onError);
  }

  return {
    callGetDetails: getDetails,
  }
}