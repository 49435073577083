import { API_BASE_URL } from './site_urls'

export const STORAGE = 'ne_ds_ui'

export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_STAGE = process.env.REACT_APP_STAGE

export const APP_URL = APP_STAGE == 'dev' ? 'https://newengland.glohtesting.com' : window.location.origin

export const URLS = {
  HOME: '/',
  REGISTRATION: '/registration',
  ABOUT_US: '/about',
  CONTACT_US: '/contact',
  SERVICES_ONLY: '/service',
  SERVICES: '/service/:slug',
  LOCATIONS: '/locations',
  FAQ: '/faq',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_WITH_TOKEN: '/reset-password/:token',
  ACCOUNT_SECTION: '/account',
  TERMS_CONDITIONS: '/terms-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  TWITTER_HELP: '/twitter-help',
  STUDENT: {
    MY_PROFILE: '/student/profile',
    MY_PACKAGES: '/student/packages',
    MY_CLASSES: '/student/classes',
    REQUEST_DRIVING: '/student/request-driving/:class_id',
    REQUEST_DRIVING_ONLY: '/student/request-driving',
    SIGNATURE: '/student/signature',
  },
  INSTRUCTOR: {
    MY_PROFILE: '/instructor/profile',
    MY_APPOINTMENTS: '/instructor/appointments',
    MY_APPOINTMENT_DETAILS: '/instructor/appointment-details/:id/:date/:s_time/:e_time',
    MY_APPOINTMENT_DETAILS_ONLY: '/instructor/appointment-details',
    MY_CALENDAR: '/instructor/calendar'
  },
  SUB_SERVICES: {
    BEHIND_THE_WHEEL: 'behind-the-wheel',
    DRIVERS_EDUCATION: 'driver-education',
    ROAD_SKILL_TEST: 'road-skill-test',
    ADULT_LESSON: 'adult-lesson',
  },
}

export const NOTICE_CONFIG = {
  [URLS.HOME]: null,
  [URLS.REGISTRATION]: 'registration',
  [URLS.ABOUT_US]: 'about-us',
  [URLS.CONTACT_US]: 'contact-us',
  [URLS.SERVICES_ONLY]: 'services',
  [URLS.LOCATIONS]: 'locations',
  [URLS.LOGIN]: 'login',
  [URLS.FORGOT_PASSWORD]: 'forgot-password',
  [URLS.RESET_PASSWORD]: 'reset-password',
  [URLS.ACCOUNT_SECTION]: 'account',
  [URLS.TERMS_CONDITIONS]: 'terms-condition',
  [URLS.PRIVACY_POLICY]: 'privacy-policy',
  [URLS.TWITTER_HELP]: 'twitter-help',
  [URLS.STUDENT.MY_PROFILE]: 'student-my-profile',
  [URLS.STUDENT.MY_PACKAGES]: 'student-my-packages',
  [URLS.STUDENT.MY_CLASSES]: 'student-my-classes',
  [URLS.STUDENT.REQUEST_DRIVING_ONLY]: 'student-request-driving',
  [URLS.INSTRUCTOR.MY_PROFILE]: 'instructor-my-profile',
  [URLS.INSTRUCTOR.MY_APPOINTMENTS]: 'instructor-my-appointments',
  [URLS.INSTRUCTOR.MY_APPOINTMENT_DETAILS_ONLY]: 'instructor-appointment-details',
  [URLS.INSTRUCTOR.MY_CALENDAR]: 'instructor-my-calendar'
}

export const API_URL = {
  USER: {
    LOGIN: `${API_BASE_URL}login`,
    REGISTRATION_AND_BUY: `${API_BASE_URL}register-and-package-buy`,
    LOGOUT: `${API_BASE_URL}logout`,
    FORGOT_PASSWORD: `${API_BASE_URL}forgot-password`,
    FORGOT_PASSWORD_VERIFY_TOKEN: `${API_BASE_URL}verify-token`,
    RESET_PASSWORD: `${API_BASE_URL}reset-password`,
    CHANGE_PASSWORD: `${API_BASE_URL}change-password`,
    DETAILS: `${API_BASE_URL}profile-details`,
    UPDATE_PROFILE_PIC: `${API_BASE_URL}profile-picture-update`,
    UPDATE_PROFILE: `${API_BASE_URL}profile-details`,
    STUDENT_AMOUNT_DUE: `${API_BASE_URL}student-package-due-amount`,
    CARD_LIST: `${API_BASE_URL}card-list`,
    REMOVE_CARD: `${API_BASE_URL}card-remove`,
  },
  COMPANY: {
    DETAILS: `${API_BASE_URL}company-details`,
  },
  PACKAGES: {
    LIST: `${API_BASE_URL}package-list`,
    MY_PACKAGE_LIST: `${API_BASE_URL}my-package-list`,
    PACKAGE_DETAILS: `${API_BASE_URL}package-details`,
    STUDENT_TRANSACTION_HISTORY: `${API_BASE_URL}package-transaction-history`,
    PARTIAL_PAYMENT: `${API_BASE_URL}package-partial-payment`,
    STUDENT_DUE_LIST: `${API_BASE_URL}student-due-amount-list`,
  },
  SERVICES: {
    LIST: `${API_BASE_URL}service-list`,
    DETAILS: `${API_BASE_URL}service-details`,
  },
  CLASSES: {
    LIST: `${API_BASE_URL}package-class-list`,
    CLASS_TYPE_LIST: `${API_BASE_URL}class-type-list`,
    SCHEDULE_LIST: `${API_BASE_URL}package-class-schedule-list`,
    MY_CLASS_LIST: `${API_BASE_URL}my-package-class-list`,
    DETAILS: `${API_BASE_URL}package-class-details`,
    INSTRUCTOR_AVAILABILITY: `${API_BASE_URL}btw-availability-list`,
    APPLY_BTW: `${API_BASE_URL}request-for-btw`,
    REMOVE_BTW_REQUEST: `${API_BASE_URL}remove-from-btw-request`,
    BTW_LIST: `${API_BASE_URL}btw-request-list`,
    STUDENT_CLASS_CONDITION: `${API_BASE_URL}student-active-class-conditions`,
    MAKEUP_CLASS_LIST: `${API_BASE_URL}student-makeup-class-availability-check`,
    BOOK_MAKEUP_CLASS: `${API_BASE_URL}student-makeup-class-request`,
    PARENT_CLASS_LIST: `${API_BASE_URL}parent-class-schedule`,
    PARENT_CLASS_UPDATE: `${API_BASE_URL}parent-class-schedule-update`,
  },
  SITE_STATICS: {
    COUNTRY_LIST: `${API_BASE_URL}country-list`,
    STATE_LIST: `${API_BASE_URL}state-list`,
    CITY_LIST: `${API_BASE_URL}city-list`,
    HIGHSCHOOL_LIST: `${API_BASE_URL}high-school-list`,
    BANNERS: `${API_BASE_URL}banners`,
    CMS_PAGES: `${API_BASE_URL}cms-list-details`,
    FAQ_LIST: `${API_BASE_URL}faq-list`,
    CONTACT_US: `${API_BASE_URL}contact-us`,
    NOTICES: `${API_BASE_URL}notice-list`,
    MENUS: `${API_BASE_URL}menus`,
    SITESETTINGS: `${API_BASE_URL}site-settings-details`,
  },
  INSTRUCTOR: {
    APPOINTMENT_LIST: `${API_BASE_URL}my-appointments`,
    APPOINTMENT_DETAILS: `${API_BASE_URL}my-appointments`,
    BOOKING_PACKAGE_LIST: `${API_BASE_URL}instructor-booking-package-list`,
    CLASS_STUDENT_LIST: `${API_BASE_URL}my-class-time-student-list`,
    ADD_UPDATE_CLASS_NOTE: `${API_BASE_URL}add-update-booking-class-time-note`,
    REMOVE_CLASS_NOTE: `${API_BASE_URL}remove-booking-class-time-note`,
    ADD_UPDATE_CLASS_STUDENT_NOTE: `${API_BASE_URL}add-update-booking-class-time-student-admin-note`,
    REMOVE_CLASS_STUDENT_NOTE: `${API_BASE_URL}remove-booking-class-time-student-admin-note`,
    AVAILABILITY: `${API_BASE_URL}instructor-availability`,
    VACATION_LIST: `${API_BASE_URL}instructor-vacation-list`,
    VACATION_ADD: `${API_BASE_URL}instructor-vacation-add`,
    VACATION_UPDATE: `${API_BASE_URL}instructor-vacation-update`,
    VACATION_REMOVE: `${API_BASE_URL}instructor-vacation-remove`,
  },
  SIGNATURE: {
    FETCH_PENDING_LIST: `${API_BASE_URL}student-today-class-list`,
    SUBMIT_NEW: `${API_BASE_URL}upload-student-signature`,
  },
  WWWWW: {
    TTTTT: `${API_BASE_URL}dddddddddd`,
  },
}

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
  },
  GLOBAL: {
    SET_LOGIN_MODAL: 'ACTIONS/GLOBAL/SET_LOGIN_MODAL',
  },
  COUNTRY_STATE_CITY: {
    COUNTRY_LIST: 'ACTIONS/COMMON/COUNTRY_LIST',
    STATE_LIST: 'ACTIONS/COMMON/STATE_LIST',
    CITY_LIST: 'ACTIONS/COMMON/CITY_LIST',
    HIGHSCHOOL_LIST: 'ACTIONS/COMMON/HIGHSCHOOL_LIST',
    RESET_COUNTRY_LIST: 'ACTIONS/COMMON/RESET_COUNTRY_LIST',
    RESET_STATE_LIST: 'ACTIONS/COMMON/RESET_STATE_LIST',
    RESET_CITY_LIST: 'ACTIONS/COMMON/RESET_CITY_LIST',
    RESET_HIGHSCHOOL_LIST: 'ACTIONS/COMMON/RESET_HIGHSCHOOL_LIST',
    RESET_ALL: 'ACTIONS/COMMON/RESET_ALL',
    BANNERS: 'ACTIONS/COMPANY/BANNERS',
    RESET_BANNERS: 'ACTIONS/COMPANY/RESET_BANNERS',
    CMS_PAGES: 'ACTIONS/COMPANY/CMS_PAGES',
    RESET_CMS_PAGES: 'ACTIONS/COMPANY/RESET_CMS_PAGES',
    FAQ_LIST: 'ACTIONS/COMPANY/FAQ_LIST',
    RESET_FAQ_LIST: 'ACTIONS/COMPANY/RESET_FAQ_LIST',
    NOTICES: 'ACTIONS/COMPANY/NOTICES',
    RESET_NOTICES: 'ACTIONS/COMPANY/RESET_NOTICES',
    MENUS: 'ACTIONS/COMPANY/MENUS',
    RESET_MENUS: 'ACTIONS/COMPANY/RESET_MENUS',
    SITESETTINGS: 'ACTIONS/COMPANY/SITESETTINGS',
    RESET_SITESETTINGS: 'ACTIONS/COMPANY/RESET_SITESETTINGS',
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    ME: 'ACTIONS/USER/ME',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    CARD_LIST: 'ACTIONS/USER/CARD_LIST',
    RESET_CARD_LIST: 'ACTIONS/USER/RESET_CARD_LIST',
  },
  SERVICE: {
    LIST: 'ACTIONS/SERVICE/LIST',
    RESET_LIST: 'ACTIONS/SERVICE/RESET_LIST',
    DETAILS: 'ACTIONS/SERVICE/DETAILS',
    RESET_DETAILS: 'ACTIONS/SERVICE/RESET_DETAILS',
  },
  CLASSES: {
    LIST: 'ACTIONS/CLASSES/LIST',
    CLASS_TYPE_LIST: 'ACTIONS/CLASSES/CLASS_TYPE_LIST',
    RESET_LIST: 'ACTIONS/CLASSES/RESET_LIST',
    MY_CLASS_LIST: 'ACTIONS/CLASSES/MY_CLASS_LIST',
    DETAILS: 'ACTIONS/CLASSES/DETAILS',
    RESET_MY_CLASS_LIST: 'ACTIONS/CLASSES/RESET_MY_CLASS_LIST',
    RESET_DETAILS: 'ACTIONS/CLASSES/RESET_DETAILS',
    INSTRUCTOR_AVAILABILITY: 'ACTIONS/CLASSES/INSTRUCTOR_AVAILABILITY',
    RESET_INSTRUCTOR_AVAILABILITY: 'ACTIONS/CLASSES/RESET_INSTRUCTOR_AVAILABILITY',
    BTW_LIST: 'ACTIONS/CLASSES/BTW_LIST',
    RESET_BTW_LIST: 'ACTIONS/CLASSES/RESET_BTW_LIST',
    STUDENT_CLASS_CONDITION: 'ACTIONS/CLASSES/STUDENT_CLASS_CONDITION',
    RESET_STUDENT_CLASS_CONDITION: 'ACTIONS/CLASSES/RESET_STUDENT_CLASS_CONDITION',
    MAKEUP_CLASS_LIST: 'ACTIONS/CLASSES/MAKEUP_CLASS_LIST',
    RESET_MAKEUP_CLASS_LIST: 'ACTIONS/CLASSES/RESET_MAKEUP_CLASS_LIST',
    PARENT_CLASS_LIST: 'ACTIONS/CLASSES/PARENT_CLASS_LIST',
    RESET_PARENT_CLASS_LIST: 'ACTIONS/CLASSES/RESET_PARENT_CLASS_LIST',
  },
  PACKAGES: {
    LIST: 'ACTIONS/PACKAGES/LIST',
    RESET_LIST: 'ACTIONS/PACKAGES/RESET_LIST',
    STUDENT_DUE_LIST: 'ACTIONS/PACKAGES/STUDENT_DUE_LIST',
    RESET_STUDENT_DUE_LIST: 'ACTIONS/PACKAGES/RESET_STUDENT_DUE_LIST',
    SET_PAYMENT_REMINDER_MODAL_STATUS: 'ACTIONS/PACKAGES/SET_PAYMENT_REMINDER_MODAL_STATUS',
    STUDENT_TRANSACTION_HISTORY: 'ACTIONS/PACKAGES/STUDENT_TRANSACTION_HISTORY',
    RESET_STUDENT_TRANSACTION_HISTORY: 'ACTIONS/PACKAGES/RESET_STUDENT_TRANSACTION_HISTORY',
    MY_PACKAGE_LIST: 'ACTIONS/PACKAGE/MY_PACKAGE_LIST',
    PACKAGE_DETAILS: 'ACTIONS/PACKAGE/PACKAGE_DETAILS',
    RESET_MY_PACKAGE_LIST: 'ACTIONS/PACKAGE/RESET_MY_PACKAGE_LIST',
    RESET_PACKAGE_DETAILS: 'ACTIONS/PACKAGE/RESET_PACKAGE_DETAILS',
  },
  SCHEDULES: {
    LIST: 'ACTIONS/SCHEDULES/LIST',
    RESET_LIST: 'ACTIONS/SCHEDULES/RESET_LIST',
  },
  COMPANY: {
    DETAILS: 'ACTIONS/COMPANY/DETAILS',
    RESET_DETAILS: 'ACTIONS/COMPANY/RESET_DETAILS',
  },
  REGISTRATION: {
    SET_STEP: 'ACTIONS/REGISTRATION/SET_STEP',
    RESET_STEP: 'ACTIONS/REGISTRATION/RESET_STEP',
    SELECT_SERVICE: 'ACTIONS/REGISTRATION/SELECT_SERVICE',
    SELECT_PACKAGE: 'ACTIONS/REGISTRATION/SELECT_PACKAGE',
    SELECT_CLASS: 'ACTIONS/REGISTRATION/SELECT_CLASS',
    RESET_SELECT_SERVICE: 'ACTIONS/REGISTRATION/RESET_SELECT_SERVICE',
    RESET_SELECT_PACKAGE: 'ACTIONS/REGISTRATION/RESET_SELECT_PACKAGE',
    RESET_SELECT_CLASS: 'ACTIONS/REGISTRATION/RESET_SELECT_CLASS',
    SELECT_DOB: {
      YEAR: 'ACTIONS/REGISTRATION/SELECT_DOB/YEAR',
      MONTH: 'ACTIONS/REGISTRATION/SELECT_DOB/MONTH',
      DAY: 'ACTIONS/REGISTRATION/SELECT_DOB/DAY',
      ALL: 'ACTIONS/REGISTRATION/SELECT_DOB/ALL',
    },
    SET_SHOW_PACKAGE: 'ACTIONS/REGISTRATION/SET_SHOW_PACKAGE',
    RESET_ALL_DOB: 'ACTIONS/REGISTRATION/RESET_ALL_DOB',
    SET_STUDENT_INFO: 'ACTIONS/REGISTRATION/SET_STUDENT_INFO',
    RESET_STUDENT_INFO: 'ACTIONS/REGISTRATION/RESET_STUDENT_INFO',
    RESET_ALL: 'ACTIONS/REGISTRATION/RESET_ALL',
  },
  INSTRUCTOR: {
    APPOINTMENT_LIST: 'ACTIONS/INSTRUCTOR/APPOINTMENT_LIST',
    RESET_APPOINTMENT_LIST: 'ACTIONS/INSTRUCTOR/RESET_APPOINTMENT_LIST',
    APPOINTMENT_DETAILS: 'ACTIONS/INSTRUCTOR/APPOINTMENT_DETAILS',
    RESET_APPOINTMENT_DETAILS: 'ACTIONS/INSTRUCTOR/RESET_APPOINTMENT_DETAILS',
    BOOKING_PACKAGE_LIST: 'ACTIONS/INSTRUCTOR/BOOKING_PACKAGE_LIST',
    RESET_BOOKING_PACKAGE_LIST: 'ACTIONS/INSTRUCTOR/RESET_BOOKING_PACKAGE_LIST',
    CLASS_STUDENT_LIST: 'ACTIONS/INSTRUCTOR/CLASS_STUDENT_LIST',
    RESET_CLASS_STUDENT_LIST: 'ACTIONS/INSTRUCTOR/RESET_CLASS_STUDENT_LIST',
    RESET_ALL: 'ACTIONS/INSTRUCTOR/RESET_ALL',
    APPOINTMENT_FILTER_DATE: 'ACTIONS/INSTRUCTOR/APPOINTMENT_FILTER_DATE',
    APPOINTMENT_FILTER_PACKAGE: 'ACTIONS/INSTRUCTOR/APPOINTMENT_FILTER_PACKAGE',
    RESET_APPOINTMENT_FILTER_DATE: 'ACTIONS/INSTRUCTOR/RESET_APPOINTMENT_FILTER_DATE',
    RESET_APPOINTMENT_FILTER_PACKAGE: 'ACTIONS/INSTRUCTOR/RESET_APPOINTMENT_FILTER_PACKAGE',
    AVAILABILITY: 'ACTIONS/INSTRUCTOR/AVAILABILITY',
    VACATION_LIST: 'ACTIONS/INSTRUCTOR/VACATION_LIST',
    RESET_AVAILABILITY: 'ACTIONS/INSTRUCTOR/RESET_AVAILABILITY',
    RESET_VACATION_LIST: 'ACTIONS/INSTRUCTOR/RESET_VACATION_LIST',
  },
  SIGNATURE: {
    FETCH_PENDING_LIST: 'ACTIONS/SIGNATURE/FETCH_PENDING_LIST',
    SET_MODAL_STATUS: 'ACTIONS/SIGNATURE/SET_MODAL_STATUS',
    SET_MODAL_SIGN_MODE: 'ACTIONS/SIGNATURE/SET_MODAL_SIGN_MODE',
    SET_MODAL_LIST_MODE: 'ACTIONS/SIGNATURE/SET_MODAL_LIST_MODE',
    SET_SELECTED_SESSION: 'ACTIONS/SIGNATURE/SET_SELECTED_SESSION',
    RESET: 'ACTIONS/SIGNATURE/RESET',
  }
}

export const SAGA_ACTIONS = {
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    DETAILS: 'SAGA_ACTIONS/USER/DETAILS',
    REGISTRATION_AND_BUY: 'SAGA_ACTIONS/USER/REGISTRATION_AND_BUY',
    FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    FORGOT_PASSWORD_VERIFY_TOKEN: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD_VERIFY_TOKEN',
    RESET_PASSWORD: 'SAGA_ACTIONS/USER/RESET_PASSWORD',
    CHANGE_PASSWORD: 'SAGA_ACTIONS/USER/CHANGE_PASSWORD',
    UPDATE_PROFILE_PIC: 'SAGA_ACTIONS/USER/UPDATE_PROFILE_PIC',
    UPDATE_PROFILE: 'SAGA_ACTIONS/USER/UPDATE_PROFILE',
    STUDENT_AMOUNT_DUE: 'SAGA_ACTIONS/USER/STUDENT_AMOUNT_DUE',
    CARD_LIST: 'SAGA_ACTIONS/USER/CARD_LIST',
    REMOVE_CARD: 'SAGA_ACTIONS/USER/REMOVE_CARD',
  },
  COMPANY: {
    DETAILS: 'SAGA_ACTIONS/COMPANY/DETAILS',
  },
  SERVICE: {
    LIST: 'SAGA_ACTIONS/SERVICE/LIST',
    DETAILS: 'SAGA_ACTIONS/SERVICE/DETAILS',
  },
  PACKAGE: {
    LIST: 'SAGA_ACTIONS/PACKAGE/LIST',
    MY_PACKAGE_LIST: 'SAGA_ACTIONS/PACKAGE/MY_PACKAGE_LIST',
    PACKAGE_DETAILS: 'SAGA_ACTIONS/PACKAGE/PACKAGE_DETAILS',
    STUDENT_TRANSACTION_HISTORY: 'SAGA_ACTIONS/PACKAGE/STUDENT_TRANSACTION_HISTORY',
    PARTIAL_PAYMENT: 'SAGA_ACTIONS/PACKAGE/PARTIAL_PAYMENT',
    STUDENT_DUE_LIST: 'SAGA_ACTIONS/PACKAGE/STUDENT_DUE_LIST',
  },
  CLASSES: {
    LIST: 'SAGA_ACTIONS/CLASSES/LIST',
    CLASS_TYPE_LIST: 'SAGA_ACTIONS/CLASSES/CLASS_TYPE_LIST',
    MY_CLASS_LIST: 'SAGA_ACTIONS/CLASSES/MY_CLASS_LIST',
    DETAILS: 'SAGA_ACTIONS/CLASSES/DETAILS',
    INSTRUCTOR_AVAILABILITY: 'SAGA_ACTIONS/CLASSES/INSTRUCTOR_AVAILABILITY',
    BTW_LIST: 'SAGA_ACTIONS/CLASSES/BTW_LIST',
    APPLY_BTW: 'SAGA_ACTIONS/CLASSES/APPLY_BTW',
    REMOVE_BTW_REQUEST: 'SAGA_ACTIONS/CLASSES/REMOVE_BTW_REQUEST',
    STUDENT_CLASS_CONDITION: 'SAGA_ACTIONS/CLASSES/STUDENT_CLASS_CONDITION',
    MAKEUP_CLASS_LIST: 'SAGA_ACTIONS/CLASSES/MAKEUP_CLASS_LIST',
    BOOK_MAKEUP_CLASS: 'SAGA_ACTIONS/CLASSES/BOOK_MAKEUP_CLASS',
    PARENT_CLASS_LIST: 'SAGA_ACTIONS/CLASSES/PARENT_CLASS_LIST',
    PARENT_CLASS_UPDATE: 'SAGA_ACTIONS/CLASSES/PARENT_CLASS_UPDATE',
  },
  SCHEDULE: {
    LIST: 'SAGA_ACTIONS/SCHEDULE/LIST',
  },
  COUNTRY_STATE_CITY: {
    COUNTRY_LIST: 'SAGA_ACTIONS/COUNTRY_STATE_CITY/COUNTRY_LIST',
    BANNERS: 'SAGA_ACTIONS/COUNTRY_STATE_CITY/BANNERS',
    CMS_PAGES: 'SAGA_ACTIONS/COUNTRY_STATE_CITY/CMS_PAGES',
    STATE_LIST: 'SAGA_ACTIONS/COUNTRY_STATE_CITY/STATE_LIST',
    CITY_LIST: 'SAGA_ACTIONS/COUNTRY_STATE_CITY/CITY_LIST',
    HIGHSCHOOL_LIST: 'SAGA_ACTIONS/COUNTRY_STATE_CITY/HIGHSCHOOL_LIST',
    FAQ_LIST: 'SAGA_ACTIONS/COUNTRY_STATE_CITY/FAQ_LIST',
    CONTACT_US: 'SAGA_ACTIONS/COUNTRY_STATE_CITY/CONTACT_US',
    NOTICES: 'SAGA_ACTIONS/COUNTRY_STATE_CITY/NOTICES',
    MENUS: 'SAGA_ACTIONS/COUNTRY_STATE_CITY/MENUS',
    SITESETTINGS: 'SAGA_ACTIONS/COUNTRY_STATE_CITY/SITESETTINGS',
  },
  INSTRUCTOR: {
    APPOINTMENT_LIST: 'SAGA_ACTIONS/INSTRUCTOR/APPOINTMENT_LIST',
    APPOINTMENT_DETAILS: 'SAGA_ACTIONS/INSTRUCTOR/APPOINTMENT_DETAILS',
    BOOKING_PACKAGE_LIST: 'SAGA_ACTIONS/INSTRUCTOR/BOOKING_PACKAGE_LIST',
    CLASS_STUDENT_LIST: 'SAGA_ACTIONS/INSTRUCTOR/CLASS_STUDENT_LIST',
    ADD_UPDATE_CLASS_NOTE: 'SAGA_ACTIONS/INSTRUCTOR/ADD_UPDATE_CLASS_NOTE',
    REMOVE_CLASS_NOTE: 'SAGA_ACTIONS/INSTRUCTOR/REMOVE_CLASS_NOTE',
    ADD_UPDATE_CLASS_STUDENT_NOTE: 'SAGA_ACTIONS/INSTRUCTOR/ADD_UPDATE_CLASS_STUDENT_NOTE',
    REMOVE_CLASS_STUDENT_NOTE: 'SAGA_ACTIONS/INSTRUCTOR/REMOVE_CLASS_STUDENT_NOTE',
    AVAILABILITY: 'SAGA_ACTIONS/INSTRUCTOR/AVAILABILITY',
    VACATION_LIST: 'SAGA_ACTIONS/INSTRUCTOR/VACATION_LIST',
    VACATION_ADD: 'SAGA_ACTIONS/INSTRUCTOR/VACATION_ADD',
    VACATION_UPDATE: 'SAGA_ACTIONS/INSTRUCTOR/VACATION_UPDATE',
    VACATION_REMOVE: 'SAGA_ACTIONS/INSTRUCTOR/VACATION_REMOVE',
  },
  SIGNATURE: {
    FETCH_PENDING_LIST: 'SAGA_ACTIONS/SIGNATURE/FETCH_PENDING_LIST',
    UPLOAD_SIGNATURE: 'SAGA_ACTIONS/SIGNATURE/UPLOAD_SIGNATURE',
  }
}

export * from './site_statics'
export * from './site_urls'
export * from './functions'
export * from './canvasUtils'
export * from './card_utils'