import { useDispatch } from 'react-redux'
import { RegistrationStep, StudentRegistrationInfo, UserDob } from '../../../store/reducers/registration/registrationReducer'
import { ACTIONS } from '../../../_config'
import { RegisterAndBuyReq } from '../../interfaces/ApiReqRes'
import { Class } from '../../interfaces/models/class'
import { Package } from '../../interfaces/models/package'
import { Service } from '../../interfaces/models/service'

export function useAppRegistrationAction() {

  const dispatch = useDispatch()

  const selectService = (data: Service) => {
    dispatch({
      type: ACTIONS.REGISTRATION.SELECT_SERVICE,
      payload: data,
    })
  }

  const selectPackage = (data: Package) => {
    dispatch({
      type: ACTIONS.REGISTRATION.SELECT_PACKAGE,
      payload: data,
    })
  }

  const selectClass = (data: Class) => {
    dispatch({
      type: ACTIONS.REGISTRATION.SELECT_CLASS,
      payload: data,
    })
  }

  const resetService = () => {
    dispatch({
      type: ACTIONS.REGISTRATION.RESET_SELECT_SERVICE,
      payload: null,
    })
  }

  const resetPackage = () => {
    dispatch({
      type: ACTIONS.REGISTRATION.RESET_SELECT_PACKAGE,
      payload: null,
    })
  }

  const resetClass = () => {
    dispatch({
      type: ACTIONS.REGISTRATION.RESET_SELECT_CLASS,
      payload: null,
    })
  }

  const resetAll = () => {
    dispatch({
      type: ACTIONS.REGISTRATION.RESET_ALL,
      payload: null,
    })
  }

  const resetDob = () => {
    dispatch({
      type: ACTIONS.REGISTRATION.RESET_ALL_DOB,
      payload: null,
    })
  }

  const setDob = (data: UserDob) => {
    dispatch({
      type: ACTIONS.REGISTRATION.SELECT_DOB.ALL,
      payload: data,
    })
  }

  const setDobYear = (data: number) => {
    dispatch({
      type: ACTIONS.REGISTRATION.SELECT_DOB.YEAR,
      payload: data,
    })
  }

  const setDobMonth = (data: number) => {
    dispatch({
      type: ACTIONS.REGISTRATION.SELECT_DOB.MONTH,
      payload: data,
    })
  }

  const setDobDay = (data: number) => {
    dispatch({
      type: ACTIONS.REGISTRATION.SELECT_DOB.DAY,
      payload: data,
    })
  }

  const setStep = (data: RegistrationStep) => {
    dispatch({
      type: ACTIONS.REGISTRATION.SET_STEP,
      payload: data,
    })
  }
  const resetStep = () => {
    dispatch({
      type: ACTIONS.REGISTRATION.RESET_STEP,
      payload: null,
    })
  }
  const setShowPackage = () => {
    dispatch({
      type: ACTIONS.REGISTRATION.SET_SHOW_PACKAGE,
      payload: true,
    })
  }
  const resetShowPackage = () => {
    dispatch({
      type: ACTIONS.REGISTRATION.SET_SHOW_PACKAGE,
      payload: false,
    })
  }

  const setStudentInfo = (info: StudentRegistrationInfo) => {
    dispatch({
      type: ACTIONS.REGISTRATION.SET_STUDENT_INFO,
      payload: info,
    })
  }
  const resetStudentInfo = () => {
    dispatch({
      type: ACTIONS.REGISTRATION.RESET_STUDENT_INFO,
      payload: null,
    })
  }


  return {
    selectService,
    selectPackage,
    selectClass,
    resetService,
    resetPackage,
    resetClass,
    resetAll,
    resetDob,
    setDob,
    setDobYear,
    setDobMonth,
    setDobDay,
    setStep,
    resetStep,
    setShowPackage,
    resetShowPackage,
    setStudentInfo,
    resetStudentInfo,
  }
}