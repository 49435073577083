import { ACTIONS, SAGA_ACTIONS } from '../../../_config'
import { BannerListReq, CityListReq, CmsPageListReq, ContactUsReq, HighSchoolListReq, LoginReq, StateListReq, MenuListReq, SiteSettingsListReq} from '../../interfaces/ApiReqRes'
import { useApiCall } from './appApiCallHook'

export function useStaticApi() {

  const callApi = useApiCall()

  const getCountryList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.COUNTRY_LIST, null, onSuccess, onError);
  }
  const getStateList = (data: StateListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.STATE_LIST, data, onSuccess, onError);
  }
  const getUSAStateList = (data: StateListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.STATE_LIST, {
      ...data,
      country_id: 231,
    }, onSuccess, onError);
  }
  const getCityList = (data: CityListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.CITY_LIST, data, onSuccess, onError);
  }
  const getSchoolList = (data: HighSchoolListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.HIGHSCHOOL_LIST, data, onSuccess, onError);
  }
  const getBanners = (data: BannerListReq, onSuccess: Function, onError: Function) => {
    console.log('calling banners')
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.BANNERS, data, onSuccess, onError);
  }
  const getCmsPages = (data: CmsPageListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.CMS_PAGES, data, onSuccess, onError);
  }
  const getFaqList = (data: BannerListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.FAQ_LIST, data, onSuccess, onError);
  }
  const getNoticeList = (data: BannerListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.NOTICES, data, onSuccess, onError);
  }
  const postContactUs = (data: ContactUsReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.CONTACT_US, data, onSuccess, onError);
  }
  const getMenuList = (data: MenuListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.MENUS, data, onSuccess, onError);
  }

  const getSiteSettingsList = (data: SiteSettingsListReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COUNTRY_STATE_CITY.SITESETTINGS, data, onSuccess, onError);
  }

  return {
    callGetCountryList: getCountryList,
    callGetStateList: getStateList,
    callGetUSAStateList: getUSAStateList,
    callGetCityList: getCityList,
    callGetSchoolList: getSchoolList,
    callGetBanners: getBanners,
    callGetCmsPages: getCmsPages,
    callGetFaqList: getFaqList,
    callGetNoticeList: getNoticeList,
    callPostContactUs: postContactUs,
    callGetMenuList: getMenuList,
    callGetSiteSettingsList: getSiteSettingsList,
  }
}