import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import AppModal from '../../_common/components/elements/app-modal/appModal'
import { useAppPackageAction } from '../../_common/hooks/actions/appPackageActionHook'
import { usePackageApi } from '../../_common/hooks/actions/appPackagesApiHook'
import { useAppSignatureAction } from '../../_common/hooks/actions/appSignatureActions'
import { useAppPackageStudentDueListSelector, useAppPaymentReminderModalShownSelector } from '../../_common/hooks/selectors/packageSelector'
import { useAppSignatureModalShownSelector } from '../../_common/hooks/selectors/signatureSelector'
import { useAppUserAuthSelector } from '../../_common/hooks/selectors/userSelector'
import { Status } from '../../_common/interfaces/models/_common'
import { URLS } from '../../_config'
import PaymentReminderModalBody from './paymentReminderModalBody'

function AppPaymentReminderRoot() {
  const packageAction = useAppPackageAction()
  const isShown = useAppPaymentReminderModalShownSelector()
  const packageApi = usePackageApi()
  const dues = useAppPackageStudentDueListSelector()
  const history = useHistory()
  const isAuth = useAppUserAuthSelector()
  const isSigModalOpen = useAppSignatureModalShownSelector()
  const signatureAction = useAppSignatureAction()
  /**
   * for testing only... remove date once UI implemented
   */
  useEffect(() => {
    if (isShown && isSigModalOpen) {
      setTimeout(() => {
        signatureAction.setHideModal()
      }, 1000)
    }
  }, [isShown, isSigModalOpen])
  useEffect(() => {
    if (dues && dues.length == 0) {
      packageAction.hidePaymentReminderModal()
    }
  }, [dues])
  useEffect(() => {
    if (isAuth) {
      packageApi.callGetStudentDueList(() => {}, () => {})
    } else {
      packageAction.hidePaymentReminderModal()
      packageAction.resetStudentDueList();
    }
  }, [isAuth])

  useEffect(() => {
    if (history && history.location.pathname == URLS.STUDENT.MY_PACKAGES) {
      if (isShown) {
        packageAction.hidePaymentReminderModal()
      }
    } else if (history && history.location.pathname != URLS.STUDENT.MY_PACKAGES) {
      if (isAuth) {
        packageApi.callGetStudentDueList(() => {}, () => {})
      }
    }
  }, [isAuth, history, isShown])

  useEffect(() => {
    if (dues && dues.length) {
      packageAction.showPaymentReminderModal();
    }
  }, [dues])

  const closePaymentReminderModal = () => {
    packageAction.hidePaymentReminderModal()
  }
  return (
    <React.Fragment>
      <AppModal
        shouldShow={isShown}
        onClose={closePaymentReminderModal}
        headerTitle="Due Payments"
        bodyClass="p-0"
        body={<PaymentReminderModalBody onClose={closePaymentReminderModal}/>}
        hideClose={true}
      />
    </React.Fragment>
  )
}

export default AppPaymentReminderRoot
