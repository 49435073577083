import React from 'react'
import { Link } from 'react-router-dom'
import { URLS } from '../../../../_config'
import { useAppCompanyDetailsSelector } from '../../../hooks/selectors/companySelector'
import { useAppUserAuthSelector, useAppUserDetailsSelector } from '../../../hooks/selectors/userSelector'

function TopLogoBar() {
  /**
   * const
   */
  const company = useAppCompanyDetailsSelector()
  const isAuth = useAppUserAuthSelector()
  const user = useAppUserDetailsSelector()

  const formatPhone = () => {
    if (!company || !company.phone) {
      return 'N/A'
    }
    return String(company.phone).replace(/^(\d{3})(\d{3})(\d{4})$/g, '($1)-$2-$3')
  }

  return (
    <div className="logo-wrap">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <Link to={URLS.HOME} className="logo"><img src={company?.media_size_original_image_url} alt="" /> </Link>
          </div>
          <div className="col-md-8">
            <div className="right-top-links">
              <a href={`mailto:${company?.email}`} className="mail"><img src="/images/mail-icon.png" alt="" /> {company?.email}
                        </a>
              <a href={`tel:${company?.phone}`} className="phone"><img src="/images/phone-icon.png" alt="" /> {formatPhone()}</a>
              {
                isAuth ? null : <Link to={URLS.REGISTRATION} className="btn-register">Register</Link>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopLogoBar
