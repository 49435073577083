let apiBaseUrl = 'https://newengland-backend.glohtesting.com/api/v1/'

let appStage = process.env.REACT_APP_STAGE

if (appStage == 'dev') {
  apiBaseUrl = 'https://newengland-backend.glohtesting.com/api/v1/'
} else if (appStage == 'stage') {
  apiBaseUrl = 'https://newengland-backend.glohtesting.com/api/v1/'
} else if (appStage == 'prod') {
  apiBaseUrl = 'https://newengland-backend.glohtesting.com/api/v1/'
}

export const API_BASE_URL = apiBaseUrl