import React from 'react'

function LoginSocial() {
  return (
    <div className="d-flex justify-content-center social-buttons">
      <button type="button" className="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Twitter">
        <i className="fa fa-twitter"></i>
      </button>
      <button type="button" className="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Facebook">
        <i className="fa fa-facebook"></i>
      </button>
      <button type="button" className="btn btn-secondary btn-round" data-toggle="tooltip" data-placement="top" title="Linkedin">
        <i className="fa fa-linkedin"></i>
      </button>
    </div>
  )
}

export default LoginSocial
