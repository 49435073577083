import { ACTIONS, SAGA_ACTIONS } from '../../../_config'
import { ChangePasswordReq, ForgotPasswordReq, ForgotPasswordVerifyTokenReq, LoginReq, RegisterAndBuyReq, RemoveCardReq, ResetPasswordReq, StudentAmountDueReq, UpdateProfilePictureReq, UpdateProfileReq } from '../../interfaces/ApiReqRes'
import { useApiCall } from './appApiCallHook'

export function useUserApi() {

  const callApi = useApiCall()

  const login = (data: LoginReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  }
  const registerAndBuy = (data: RegisterAndBuyReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTRATION_AND_BUY, data, onSuccess, onError);
  }

  const getMe = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.DETAILS, null, onSuccess, onError);
  }
  const logout = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGOUT, null, onSuccess, onError);
  }

  const forgotPassword = (data: ForgotPasswordReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError);
  }
  const forgotPasswordVerifyToken = (data: ForgotPasswordVerifyTokenReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD_VERIFY_TOKEN, data, onSuccess, onError);
  }
  const resetPassword = (data: ResetPasswordReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError);
  }
  const changePassword = (data: ChangePasswordReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CHANGE_PASSWORD, data, onSuccess, onError);
  }
  const updateProfile = (data: UpdateProfileReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_PROFILE, data, onSuccess, onError);
  }
  const updateProfilePicture = (data: UpdateProfilePictureReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.UPDATE_PROFILE_PIC, data, onSuccess, onError);
  }

  const getStudentTotalAmountDue = (data: StudentAmountDueReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.STUDENT_AMOUNT_DUE, data, onSuccess, onError);
  }

  const getUserCardList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CARD_LIST, {}, onSuccess, onError);
  }
  const removeUserCard = (data: RemoveCardReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REMOVE_CARD, data, onSuccess, onError);
  }


  return {
    callLogin: login,
    callLogout: logout,
    callGetMe: getMe,
    callRegisterAndBuyPackage: registerAndBuy,
    callForgotPassword: forgotPassword,
    callForgotPasswordVerifyToken: forgotPasswordVerifyToken,
    callResetPassword: resetPassword,
    callChangePassword: changePassword,
    callUpdateProfile: updateProfile,
    callUpdateProfilePicture: updateProfilePicture,
    callGetStudentTotalAmountDue: getStudentTotalAmountDue,
    callGetUserCardList: getUserCardList,
    callRemoveUserCard: removeUserCard,
  }
}