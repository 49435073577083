import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { PendingSignature } from '../../../_common/interfaces/models/pendingSignature';
import { ACTIONS } from '../../../_config'

import moment from 'moment'

export enum SignatureModalMode {
  list = 1,
  sign = 2,
}

export interface SignatureReducer {
  isShown: boolean;
  mode: SignatureModalMode;
  list: PendingSignature[];
  selected: PendingSignature | null;
}

const initialState: SignatureReducer = {
  isShown: false,
  mode: SignatureModalMode.list,
  list: [],
  selected: null,
};

const signatureReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.SIGNATURE.SET_MODAL_STATUS:
      return {
        ...state,
        isShown: action.payload,
      };
    case ACTIONS.SIGNATURE.SET_MODAL_LIST_MODE:
      return {
        ...state,
        mode: SignatureModalMode.list,
      };
    case ACTIONS.SIGNATURE.SET_MODAL_SIGN_MODE:
      return {
        ...state,
        mode: SignatureModalMode.sign,
      };
    case ACTIONS.SIGNATURE.FETCH_PENDING_LIST:
      return {
        ...state,
        list: (action.payload as PendingSignature[]).map(s => {
          let sDate = moment(`${s.s_date} ${s.start_time}`, 'YYYY-MM-DD HH:mm')
          let eDate = moment(`${s.s_date} ${s.end_time}`, 'YYYY-MM-DD HH:mm')
          return {
            ...s,
            formattedDateString: `${sDate.format('MMMM DD, YYYY')} @ ${sDate.format('hh:mm A')} to ${eDate.format('hh:mm A')}`
          }
        }),
      };
    case ACTIONS.SIGNATURE.SET_SELECTED_SESSION:
      return {
        ...state,
        selected: action.payload,
      };
    case ACTIONS.SIGNATURE.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default signatureReducer;
