import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../_config';
import { CallApi } from './api/callApi';

export function* getInstructorAppointmentList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.INSTRUCTOR.APPOINTMENT_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.INSTRUCTOR.APPOINTMENT_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getInstructorAppointmentDetails(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.INSTRUCTOR.APPOINTMENT_DETAILS, data, true);
    console.log('resp', resp.data)
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.INSTRUCTOR.APPOINTMENT_DETAILS,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getInstructorPackageList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.INSTRUCTOR.BOOKING_PACKAGE_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.INSTRUCTOR.BOOKING_PACKAGE_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getInstructorClassStudentList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.INSTRUCTOR.CLASS_STUDENT_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.INSTRUCTOR.CLASS_STUDENT_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* addUpdateInstructorClassNote(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.INSTRUCTOR.ADD_UPDATE_CLASS_NOTE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* removeInstructorClassNote(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.INSTRUCTOR.REMOVE_CLASS_NOTE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* addUpdateInstructorStudentNote(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.INSTRUCTOR.ADD_UPDATE_CLASS_STUDENT_NOTE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* removeInstructorStudentNote(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.INSTRUCTOR.REMOVE_CLASS_STUDENT_NOTE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getInstructorAvailabilityList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.INSTRUCTOR.AVAILABILITY, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.INSTRUCTOR.AVAILABILITY,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getInstructorVacationList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.INSTRUCTOR.VACATION_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.INSTRUCTOR.VACATION_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* addInstructorVacation(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.INSTRUCTOR.VACATION_ADD, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updateInstructorVacation(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.INSTRUCTOR.VACATION_UPDATE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* removeInstructorVacation(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.INSTRUCTOR.VACATION_REMOVE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}