import React from 'react';

const LazyMyAppointments = React.lazy(() =>
  import(/* webpackChunkName: "MyAppointments" */ './myappointments')
);

const MyAppointments = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyMyAppointments {...props} />
  </React.Suspense>
);

export default MyAppointments