import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../../_config'

export function useAppPackageAction() {

  const dispatch = useDispatch()

  const resetTransactionHistory = () => {
    dispatch({
      type: ACTIONS.PACKAGES.RESET_STUDENT_TRANSACTION_HISTORY,
      payload: null,
    })
  }
  const resetPackageList = () => {
    dispatch({
      type: ACTIONS.PACKAGES.RESET_LIST,
      payload: null,
    })
  }
  const resetMyPackageList = () => {
    dispatch({
      type: ACTIONS.PACKAGES.RESET_MY_PACKAGE_LIST,
      payload: null,
    })
  }
  const resetPackageDetails = () => {
    dispatch({
      type: ACTIONS.PACKAGES.RESET_PACKAGE_DETAILS,
      payload: null,
    })
  }
  const resetStudentDueList = () => {
    dispatch({
      type: ACTIONS.PACKAGES.RESET_STUDENT_DUE_LIST,
      payload: null,
    })
  }
  const showPaymentReminderModal = () => {
    dispatch({
      type: ACTIONS.PACKAGES.SET_PAYMENT_REMINDER_MODAL_STATUS,
      payload: true,
    })
  }
  const hidePaymentReminderModal = () => {
    dispatch({
      type: ACTIONS.PACKAGES.SET_PAYMENT_REMINDER_MODAL_STATUS,
      payload: false,
    })
  }

  return {
    resetTransactionHistory,
    resetPackageList,
    resetMyPackageList,
    resetPackageDetails,
    resetStudentDueList,
    showPaymentReminderModal,
    hidePaymentReminderModal,
  }
}