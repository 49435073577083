import { type } from 'os';
import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { Company } from '../../../_common/interfaces/models/company';
import { Service } from '../../../_common/interfaces/models/service';
import { ACTIONS } from '../../../_config'

export interface CompanyReducer {
  details: Company | null;
}

const initialState: CompanyReducer = {
  details: null,
};

const companyReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.COMPANY.DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case ACTIONS.COMPANY.RESET_DETAILS:
      return {
        ...state,
        details: null,
      };
    default:
      return state;
  }
};

export default companyReducer;
