import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter, HashRouter, Redirect, useHistory } from 'react-router-dom';
import './App.css';

import { APP_URL, URLS } from './_config';

import requireNoAuth from './_common/hoc/reqNoAuth'
import requireAuth from './_common/hoc/reqAuth'
import reqLayout from './_common/hoc/layout';

import Home from './containers/home'
import AboutUs from './containers/aboutus'
import Contact from './containers/contactus'
import Faq from './containers/faq'
import TwitterHelp from './containers/twitter-help'
import InstructorAppointments from './containers/instructor/myappointments'
import InstructorMyProfile from './containers/instructor/myprofile'
import InstructorMyCalendar from './containers/instructor/mycalendar'
import InstructorAppointmentDetails from './containers/instructor/appointment-details'
import Locations from './containers/locations'
import Registration from './containers/registration'
import Services from './containers/services'
import StudentMyClasses from './containers/student/myclasses'
import StudentMyPackages from './containers/student/mypackages'
import StudentMyProfile from './containers/student/myprofile'
import StudentRequestDriving from './containers/student/request-driving'
import StudentSignature from './containers/student/signature'

import Login from './containers/login'
import ForgotPassword from './containers/forgotpassword'
import ResetPassword from './containers/resetpassword'

import PrivacyPolicy from './containers/privacy-policy'
import TermsCondition from './containers/terms-condition'

import Page404 from './containers/page-404'
import { useCompanyApi } from './_common/hooks/actions/appCompanyApiHook';
import { useToaster } from './_common/hooks/actions/appToasterHook';
import { useAppFeedback } from './_common/hooks/actions/appFeedbackHook';
import { useAppCompanyDetailsSelector } from './_common/hooks/selectors/companySelector';
import { useServiceApi } from './_common/hooks/actions/appServiceApiHook';
import { useAppRegistrationAction } from './_common/hooks/actions/appRegistrationActionHook';
import LoginModal from './components/login/loginModal';
import { useStaticApi } from './_common/hooks/actions/appStaticApiHook';
import { UserType } from './_common/interfaces/models/_common';

function App() {

  const companyApi = useCompanyApi()
  const serviceApi = useServiceApi()
  const toast = useToaster();
  const staticApi = useStaticApi();

  const companyDetails = useAppCompanyDetailsSelector()

  const registrationAction = useAppRegistrationAction()

  /**
   * effects
   */

  useEffect(() => {
    if (companyDetails && companyDetails.id) {
      serviceApi.callServiceList({
        company_id: companyDetails.id,
      }, ()=>{}, (message: string)=>{
        toast.error(message) 
      })
      // staticApi.callGetBanners({
      //   company_id: companyDetails.id,
      // }, ()=>{}, (message: string)=>{
      //   toast.error(message) 
      // })
    }
  }, [companyDetails])

  useEffect(() => {
    registrationAction.resetAll();
    companyApi.callGetDetails({
      domain: APP_URL,
    }, () => {}, (message: string) => {
      toast.error(message)
    })

    try {
      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `https://newengland-backend.glohtesting.com/get/dynamic/${document.location.hostname}/style.css`;
      // link.href = `https://newengland-backend.glohtesting.com/get/dynamic/newengland.glohtesting.com/style.css`;
      document.head.appendChild(link);
    }catch(e) {
      console.log('exception', e)
    }
  }, [])

  return (
    <Router>
      <Switch>
        <Route exact path={URLS.HOME} component={reqLayout(Home)} />
        <Route exact path={URLS.LOGIN} component={requireNoAuth(Login)} />
        <Route exact path={URLS.FORGOT_PASSWORD} component={requireNoAuth(ForgotPassword)} />
        <Route exact path={URLS.RESET_PASSWORD_WITH_TOKEN} component={requireNoAuth(ResetPassword)} />
        <Route exact path={URLS.ABOUT_US} component={reqLayout(AboutUs)} />
        <Route exact path={URLS.CONTACT_US} component={reqLayout(Contact)} />
        <Route exact path={URLS.FAQ} component={reqLayout(Faq)} />
        <Route exact path={URLS.TWITTER_HELP} component={reqLayout(TwitterHelp)} />
        <Route exact path={URLS.TERMS_CONDITIONS} component={reqLayout(TermsCondition)} />
        <Route exact path={URLS.PRIVACY_POLICY} component={reqLayout(PrivacyPolicy)} />
        {/* TODO: add role here */}
        {/* TODO: for now allowing all, will req auth */}
        <Route exact path={URLS.INSTRUCTOR.MY_APPOINTMENTS} component={requireAuth(InstructorAppointments, UserType.instructor)} />
        <Route exact path={URLS.INSTRUCTOR.MY_PROFILE} component={requireAuth(InstructorMyProfile, UserType.instructor)} />
        <Route exact path={URLS.INSTRUCTOR.MY_CALENDAR} component={requireAuth(InstructorMyCalendar, UserType.instructor)} />
        <Route exact path={URLS.INSTRUCTOR.MY_APPOINTMENT_DETAILS} component={requireAuth(InstructorAppointmentDetails, UserType.instructor)} />
        <Route exact path={URLS.LOCATIONS} component={reqLayout(Locations)} />
        <Route exact path={URLS.REGISTRATION} component={reqLayout(Registration)} />
        <Route path={URLS.SERVICES} component={reqLayout(Services)}/>
        {/* TODO: add role here */}
        {/* TODO: for now allowing all, will req & role permission auth */}
        <Route exact path={URLS.STUDENT.MY_CLASSES} component={requireAuth(StudentMyClasses, UserType.student)} />
        <Route exact path={URLS.STUDENT.MY_PACKAGES} component={requireAuth(StudentMyPackages, UserType.student)} />
        <Route exact path={URLS.STUDENT.MY_PROFILE} component={requireAuth(StudentMyProfile, UserType.student)} />
        <Route exact path={URLS.STUDENT.REQUEST_DRIVING} component={requireAuth(StudentRequestDriving, UserType.student)} />
        <Route exact path={URLS.STUDENT.SIGNATURE} component={requireAuth(StudentSignature, UserType.student)} />
        <Route path="*" component={reqLayout(Page404)} />
      </Switch>
      <LoginModal/>
    </Router>
  );
}

export default App;
