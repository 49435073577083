import React, { useEffect, useState } from 'react'
import AppModal from '../../_common/components/elements/app-modal/appModal'
import { useClassesApi } from '../../_common/hooks/actions/appClassesApiHook'
import { useAppParentClassListSelector } from '../../_common/hooks/selectors/classSelector'
import { useAppUserAuthSelector } from '../../_common/hooks/selectors/userSelector'
import AppParentModalBody from './appParentModalBody'

function AppParentModalRoot() {

  const [ modalShown, setModalShown ] = useState(false)

  const classApi = useClassesApi()

  const parentClasses = useAppParentClassListSelector()

  const isLoggedIn = useAppUserAuthSelector()

  useEffect(() => {
    if (isLoggedIn) {
      classApi.callGetParentClassList(() => {
      }, () => {
        closeModal()
      })
    } else {
      closeModal()
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (parentClasses && parentClasses.length) {
      openModal()
    } else {
      closeModal()
    }
  }, [parentClasses])

  const closeModal = () => {
    setModalShown(false)
  }
  const openModal = () => {
    setModalShown(true)
  }

  return (
    <React.Fragment>
      <AppModal
        shouldShow={modalShown}
        onClose={closeModal}
        headerTitle="Parent Class Schedule"
        bodyClass="p-0"
        body={<AppParentModalBody/>}
        hideClose={false}
      />
    </React.Fragment>
  )
}

export default AppParentModalRoot
