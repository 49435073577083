import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Service } from '../../interfaces/models/service'
import { StateExtended } from '../../interfaces/StateExtended'


export function useAppServiceListSelector() {
  const list: Service[] = useSelector((state: StateExtended) => state.service.serviceList)

  return list
}

export function useAppServiceDetailsSelector() {
  const details: Service | null = useSelector((state: StateExtended) => state.service.details)

  return details
}