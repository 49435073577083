import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, SAGA_ACTIONS, STORAGE } from '../_config';
import { CallApi } from './api/callApi';

export function* loginUser(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      localStorage.setItem(STORAGE, resp.data.token);
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          user: resp.data.data,
          token: resp.data.token,
        }
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* registerWithPackage(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.REGISTRATION_AND_BUY, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* getUserProfile(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.USER.DETAILS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: ACTIONS.USER.ME,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getStudentDueAmount(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.USER.STUDENT_AMOUNT_DUE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updateProfile(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.UPDATE_PROFILE, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: SAGA_ACTIONS.USER.DETAILS,
        payload: null,
        callbackSuccess: () => {
          action && action.callbackSuccess && action.callbackSuccess(resp.data);
        },
        callbackError: () => {
          action && action.callbackSuccess && action.callbackSuccess(resp.data);
        }
      })
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* updateUserProfilePic(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.UPDATE_PROFILE_PIC, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: SAGA_ACTIONS.USER.DETAILS,
        payload: null,
        callbackSuccess: () => {
          action && action.callbackSuccess && action.callbackSuccess(resp.data);
        },
        callbackError: () => {
          action && action.callbackSuccess && action.callbackSuccess(resp.data);
        }
      })
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* changePassword(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.CHANGE_PASSWORD, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* logoutUser(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGOUT, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      localStorage.removeItem(STORAGE)
      yield put({
        type: ACTIONS.USER.LOGOUT,
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* forgotPassword(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.FORGOT_PASSWORD, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* forgotPasswordVerifyToken(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.FORGOT_PASSWORD_VERIFY_TOKEN, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* resetPassword(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.RESET_PASSWORD, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.success) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}


export function* getCardList(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.USER.CARD_LIST, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.USER.CARD_LIST,
        payload: resp.data.data
      })
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* removeCard(action: any):  any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.REMOVE_CARD, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data && resp.data.data) {
      yield put({
        type: SAGA_ACTIONS.USER.CARD_LIST,
        payload: null,
        callbackSuccess: () => {
          action && action.callbackSuccess && action.callbackSuccess(resp.data);
        },
        callbackError: () => {
          action && action.callbackSuccess && action.callbackSuccess(resp.data);
        }
      })
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}