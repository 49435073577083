import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { OptionValue } from '../../../_common/interfaces/common';
import { InstructorAppointment } from '../../../_common/interfaces/models/instructorAppointment';
import { InstructorAvailability } from '../../../_common/interfaces/models/instructorAvailability';
import { InstructorBTWAppointment } from '../../../_common/interfaces/models/instructorBtwAppointment';
import { InstructorClassStudent } from '../../../_common/interfaces/models/instructorClassStudent';
import { InstructorPackage } from '../../../_common/interfaces/models/instructorPackage';
import { InstructorVacation } from '../../../_common/interfaces/models/instructorVacation';
import { ACTIONS } from '../../../_config'

export interface InsAppointmentList {
  sessions: InstructorAppointment[];
  btw: InstructorBTWAppointment[];
}

export interface InstructorReducer {
  appointmentList: InsAppointmentList | null;
  packageList: InstructorPackage[];
  studentList: InstructorClassStudent[];
  availabilityList: InstructorAvailability[];
  vacationList: InstructorVacation[];
  appointmentDetails: InstructorAppointment | null;
  filterDate: Date | undefined;
  filterPackage: OptionValue | undefined;
}

const initialState: InstructorReducer = {
  appointmentList: null,
  packageList: [],
  studentList: [],
  availabilityList: [],
  vacationList: [],
  filterDate: undefined,
  filterPackage: undefined,
  appointmentDetails: null,
};

const instructorReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.INSTRUCTOR.APPOINTMENT_LIST:
      return {
        ...state,
        appointmentList: action.payload,
      };
    case ACTIONS.INSTRUCTOR.RESET_APPOINTMENT_LIST:
      return {
        ...state,
        appointmentList: [],
      };
    case ACTIONS.INSTRUCTOR.APPOINTMENT_DETAILS:
      return {
        ...state,
        appointmentDetails: action.payload,
      };
    case ACTIONS.INSTRUCTOR.RESET_APPOINTMENT_DETAILS:
      return {
        ...state,
        appointmentDetails: null,
      };
    case ACTIONS.INSTRUCTOR.BOOKING_PACKAGE_LIST:
      return {
        ...state,
        packageList: action.payload,
      };
    case ACTIONS.INSTRUCTOR.RESET_BOOKING_PACKAGE_LIST:
      return {
        ...state,
        packageList: [],
      };
    case ACTIONS.INSTRUCTOR.CLASS_STUDENT_LIST:
      return {
        ...state,
        studentList: action.payload,
      };
    case ACTIONS.INSTRUCTOR.RESET_CLASS_STUDENT_LIST:
      return {
        ...state,
        studentList: [],
      };
    case ACTIONS.INSTRUCTOR.APPOINTMENT_FILTER_DATE:
      return {
        ...state,
        filterDate: action.payload,
      };
    case ACTIONS.INSTRUCTOR.RESET_APPOINTMENT_FILTER_DATE:
      return {
        ...state,
        filterDate: [],
      };
    case ACTIONS.INSTRUCTOR.APPOINTMENT_FILTER_PACKAGE:
      return {
        ...state,
        filterPackage: action.payload,
      };
    case ACTIONS.INSTRUCTOR.RESET_APPOINTMENT_FILTER_PACKAGE:
      return {
        ...state,
        filterPackage: [],
      };
    case ACTIONS.INSTRUCTOR.AVAILABILITY:
        return {
          ...state,
          availabilityList: action.payload,
        };
    case ACTIONS.INSTRUCTOR.RESET_AVAILABILITY:
        return {
          ...state,
          availabilityList: [],
        };
    case ACTIONS.INSTRUCTOR.VACATION_LIST:
        return {
          ...state,
          vacationList: action.payload,
        };
    case ACTIONS.INSTRUCTOR.RESET_VACATION_LIST:
        return {
          ...state,
          vacationList: [],
        };
    case ACTIONS.INSTRUCTOR.RESET_ALL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default instructorReducer;
