import React from 'react';

const LazyMyPackages = React.lazy(() =>
  import(/* webpackChunkName: "MyPackages" */ './mypackages')
);

const MyPackages = (props: Record<string, any>) => (
  <React.Suspense fallback={<h1>Loading...</h1>}>
    <LazyMyPackages {...props} />
  </React.Suspense>
);

export default MyPackages