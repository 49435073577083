import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { City } from '../../interfaces/models/city'
import { CmsPage } from '../../interfaces/models/cms'
import { Country } from '../../interfaces/models/country'
import { Faq } from '../../interfaces/models/faq'
import { HomeBanner } from '../../interfaces/models/homeBanner'
import { Notice } from '../../interfaces/models/notice'
import { School } from '../../interfaces/models/school'
import { State } from '../../interfaces/models/state'
import { StateExtended } from '../../interfaces/StateExtended'
import { Menu } from '../../interfaces/models/Menu'
import { SiteSettings } from '../../interfaces/models/siteSettings'


export function useAppCountryListSelector() {
  const list: Country[] = useSelector((state: StateExtended) => state.countryStateCity.countryList)

  return list
}
export function useAppStateListSelector() {
  const list: State[] = useSelector((state: StateExtended) => state.countryStateCity.stateList)

  return list
}
export function useAppCityListSelector() {
  const list: City[] = useSelector((state: StateExtended) => state.countryStateCity.cityList)

  return list
}
export function useAppHighSchoolListSelector() {
  const list: School[] = useSelector((state: StateExtended) => state.countryStateCity.highSchools)

  return list
}
export function useAppBannersSelector() {
  const list: HomeBanner[] = useSelector((state: StateExtended) => state.countryStateCity.banners)

  return list
}
export function useAppCmsPagesSelector() {
  const list: CmsPage[] = useSelector((state: StateExtended) => state.countryStateCity.cmsPages)

  return list
}
export function useAppFaqListSelector() {
  const list: Faq[] = useSelector((state: StateExtended) => state.countryStateCity.faqList)

  return list
}
export function useAppNoticeListSelector() {
  const list: Notice[] = useSelector((state: StateExtended) => state.countryStateCity.noticeList)

  return list
}
export function useAppMenuListSelector() {
  const list: Menu[] = useSelector((state: StateExtended) => state.countryStateCity.menuList)

  return list
}

export function useAppSiteSettingsListSelector() {
  const list: SiteSettings[] = useSelector((state: StateExtended) => state.countryStateCity.siteSettingsList)

  return list
}