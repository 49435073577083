import React, { useEffect } from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';
import FormTextInput from '../../_common/components/form-elements/textinput/formTextInput';
import { useAppGlobalAction } from '../../_common/hooks/actions/appGlobalActionHook';
import { Link, useHistory } from 'react-router-dom';
import { URLS } from '../../_config';
import classnames from 'classnames'

import './login.css';
import { useUserApi } from '../../_common/hooks/actions/appUserApiHook';
import { useToaster } from '../../_common/hooks/actions/appToasterHook';
import { useAppCompanyDetailsSelector } from '../../_common/hooks/selectors/companySelector';

interface LoginFormValues {
  email: string;
  password: string;
}
interface LoginFormProps {
  from: 'modal' | 'page'
}

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please provide valid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password should have minimum 6 characters')
    .max(20, 'Max 20 characters are allowed')
})

function LoginForm({
  from,
}: LoginFormProps) {
  /**
   * const
   */
  const { register, control, setValue, handleSubmit, errors } = useForm<LoginFormValues>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })
  const globalActions = useAppGlobalAction()
  const history = useHistory()
  const userApi = useUserApi()
  const toast = useToaster()
  const companyDetails = useAppCompanyDetailsSelector()
  const loginBtnClass = classnames({
    'btn btn-primary btn-round': true,
    'btn-block': from == "modal",
    'btn-min-width': from == "page",
  })
  /**
   * effects
   */
  /**
   * functions
   */

  const onSubmit = (values: LoginFormValues) => {
    userApi.callLogin({
      ...values,
      company_id: companyDetails?.id
    }, () => {
      if (from == "modal") {
        globalActions.hideLoginModal()
      }
    }, (message: string) => {
      toast.error(message)
    })
  }

  const gotoForgotPassword = (evt: React.MouseEvent) => {
    evt && evt.preventDefault()
    globalActions.hideLoginModal()
    history.push(URLS.FORGOT_PASSWORD)
  }

  return (
    <div className="d-flex flex-column text-center">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-group">
          <Controller
            control={control}
            name="email"
            render={({onChange, onBlur, value, name, ref}) => (
              <FormTextInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                type="email"
                error={errors.email}
                placeholder="Your email address..."
              />
            )}
          />
        </div>
        <div className="form-group">
          <Controller
            control={control}
            name="password"
            render={({onChange, onBlur, value, name, ref}) => (
              <FormTextInput
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                type="password"
                error={errors.password}
                placeholder="Your password..."
              />
            )}
          />
        </div>
        <div className="form-group col-12 forgot-password-block">
          Forgot password? {
            from == "modal" ? <><a href="#" onClick={gotoForgotPassword}>Click here</a> to reset </> : <><Link to={URLS.FORGOT_PASSWORD}>Click here</Link> to reset</>
          }
        </div>
        <button type="submit" className={loginBtnClass}>Login</button>
      </form>
    </div>
  )
}

export default LoginForm
